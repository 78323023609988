.searchbar_searchbox {
  // margin: 15px 0;
  width: 100%;
  position: relative;
  .search_container {
    position: absolute;
    top: 10px;
  }
  .search_suggestions {
    display: block !important;
    border-radius: 5px;
    position: absolute;
    background-color: rgba(6, 6, 14, 1);
    top: 41px;
    max-width: 400px;
    // -webkit-box-orient: vertical;
    // -webkit-box-direction: normal;
    // -ms-flex-direction: column;
    // flex-direction: column;
    top: 51px;
    -webkit-box-shadow: 0 5px 10px #000;
    box-shadow: 0 5px 10px #000;
    padding-bottom: 10px;
    padding-left: 0px;
    padding-top: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 201;
    min-height: 250px;
    border: 1px solid orange;
    li {
      white-space: nowrap;
      display: flex !important;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      list-style: none;
      cursor: pointer;
      margin: 0 0 0 0 !important;
      padding: 5px !important;
      color: #9f9e9e;
      img {
        width: 40px;
        margin-right: 10px;
        border-radius: 5px;
      }
      a {
        color: #fff;
        text-decoration: none;
        font-weight: 500;
      }

      &:hover {
        background: #f9a9157a;
      }
    }
    &::before {
      // content: url("../assets/images/common/angle-up-solid.svg");
      // width: 10px;
      // height: 10px;
      // position: absolute;
      // top: -10px;
      // left: 10px;
    }
  }
  input {
    width: 100%;
    height: 36px;
    padding-left: 25px;
    border-color: #161616;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    color: #ccc;
    background: #3d3d3d6a;
    &:focus {
      outline: none;
      box-shadow: none;
      border: none;
      background: #3d3d3d6a;
      color: #ccc;
    }
    &::placeholder {
      color: #6b6969;
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder {
      /* Edge 12 -18 */
      color: #6b6969;
    }
  }

  .search-icon {
    position: absolute;
    top: 7px;
    left: 5px;
    .fas {
      color: #9f9e9e;
    }
  }

  .caretUp {
    position: absolute;
    left: 5px;
    top: 29px;
    z-index: 202;
    svg {
      path {
        fill: orange;
      }
    }
  }
}
