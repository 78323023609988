$footerBackground: #11112b;

footer {
  .footer {
    background: $footerBackground !important;
    border-radius: 25px;
    margin: 10px;
    .brands.our-Brands {
      p {
        font-weight: 400;
        color: $textColor;
        font-size: 14px;
        a {
          color: orange;
        }
        @media (max-width: 767px) {
          font-size: 12px;
        }
      }
    }
    .quicklinks {
      .section_title {
        color: $textColor;
      }
      ul {
        li {
          a {
            font-size: 14px;
            color: $textColor;
          }
        }
      }
    }
    .brands {
      .section_title {
        color: $textColor;
      }
    }
    .footer_18_plus_icon {
      width: 42px;
      height: 42px;
      border: 2px solid white;
      border-radius: 50%;
      svg {
        fill: white;
        path {
          fill: white;
        }
      }
    }
    .footer_description {
      color: $textColor;
      font-weight: 400;
      font-size: 14px;
      @media (max-width: 767px) {
        font-size: 12px;
      }

      a {
        color: orange;
      }
    }
  }
}

.footer {
  background: linear-gradient(160deg, #4ea03e 0%, #1e5128 100%);
  padding: 75px 0 0px;
  @media (max-width: 991px) {
    padding: 55px 0 0px;
  }
  .Footer_logo {
    margin-top: 15px;
    // margin-bottom: 25px;
    @media (max-width: 767px) {
      text-align: center;
    }
    a {
      img {
        height: 40px;
        // @media (min-width: 768px) {
        //   height: 80px;
        // }
        // @media (max-width: 1199px) {
        //   height: 55px;
        // }
        // @media (max-width: 991px) {
        //   height: 60px;
        // }
      }
    }
  }
  .section_title {
    font-size: 14px;
    line-height: 29px;
    color: $white;
    font-weight: 700;
    margin-bottom: 25px;
    text-transform: uppercase;
    @media (max-width: 991px) {
      margin-bottom: 15px;
    }
    @media (max-width: 767px) {
      text-align: center;
    }
  }
  .footerLink--box {
    margin-bottom: 45px;
  }
  .quicklinks {
    @media (max-width: 767px) {
      text-align: center;
      margin-bottom: 30px;
    }
    ul {
      margin: 0;
      padding: 0;
      @media (min-width: 440px) {
        display: block;
        justify-content: space-evenly;
        flex-grow: 1;
      }
      li {
        padding: 10px 10px 0;
        list-style: none;
        font-family: $font;
        font-style: normal;
        font-weight: 400;

        @media (max-width: 1199px) {
          padding: 5px 5px 0;
        }
        font-size: 15px;
        text-transform: capitalize;
        @media (max-width: 767px) {
          padding: 5px;
        }
        span.linkIcon {
          padding-right: 10px;
        }
        img {
          width: 100%;
          @media (max-width: 440px) {
            width: 60px;
          }
        }
        a {
          text-decoration: none;
          color: $white;
          font-size: 15px;
          font-weight: 400;
          &:hover {
            color: orange;
          }
          @media (max-width: 1199px) {
            font-size: 14px;
          }
        }
      }
    }
  }
  .brands {
    padding-top: 0;
    @media (max-width: 991px) {
      margin-bottom: 30px;
    }
    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
    &.our-Brands {
      @media (max-width: 991px) {
        margin-bottom: 30px;
      }
      @media (max-width: 767px) {
        text-align: center;
        margin-bottom: 20px;
      }
      ul {
        padding-top: 0px;
        display: flex;
        li {
          float: left;
          padding-left: 0;
          a {
            text-decoration: underline;
            svg {
              width: 70px;
            }
          }
        }
      }
      p {
        font-size: 15px;
        font-weight: 500;
        line-height: 19px;
        color: $white;
        a {
          color: $yellow-highlight;
        }
        @media (max-width: 1199px) {
          font-size: 13px;
        }
      }
    }
    &.socials-Link {
      @media (max-width: 991px) {
        margin-bottom: 20px;
      }
      ul {
        display: flex;
        position: relative;
        margin-top: 8px;
        grid-gap: 20px;
        @media (max-width: 767px) {
          justify-content: center;
        }
        li {
          list-style: none;
          padding-right: 0px !important;
          a {
            width: 47px;
            height: 47px;
            background-color: #fff;
            text-align: center;
            line-height: 47px;
            font-size: 22px;
            // margin: 0 10px;
            display: block;
            border-radius: 50%;
            position: relative;
            overflow: hidden;
            border: 2px solid #fff;
            z-index: 1;
            @media (max-width: 1199px) {
              width: 34px;
              height: 34px;
              line-height: 34px;
              font-size: 14px;
              margin: 0 5px;
            }
            .icon {
              position: relative;
              color: #262626;
              transition: 0.5s;
              z-index: 3;
            }
            &:hover {
              .icon {
                color: #fff;
                transform: rotateY(360deg);
              }
              &:before {
                top: 0;
              }
            }
            &:before {
              content: "";
              position: absolute;
              top: 100%;
              left: 0;
              width: 100%;
              height: 100%;
              background: #3b5999;
              transition: 0.5s;
              z-index: 2;
            }
          }
          &li:nth-child(1) a:before {
            background: #3b5999;
          }
          &:nth-child(2) a:before {
            background: #c13584;
          }
          &:nth-child(3) a:before {
            background: #0077b5;
          }
          &:nth-child(4) a:before {
            background: #dd4b39;
          }
        }
      }
    }
    ul {
      margin: 0;
      padding: 0;
      @media (min-width: 440px) {
        display: block;
        justify-content: left;
        flex-grow: 1;
        align-items: center;
      }
      li {
        padding: 0px 15px 15px;
        list-style: none;
        font-family: $font;
        font-style: normal;
        font-weight: 400;
        // float: left;
        text-transform: capitalize;
        padding-left: 0;
        @media (max-width: 767px) {
          padding: 10px;
        }
        img {
          width: 100%;
          @media (max-width: 440px) {
            width: 60px;
          }
        }
        a {
          text-decoration: none;
          color: $white;
          font-size: 16px;
          font-weight: 400;
          &:hover {
            color: $yellow-highlight;
          }
          svg {
            width: 50px;
          }
          @media (max-width: 1199px) {
            font-size: 13px;
          }
        }
      }
    }
  }

  .footer_description {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    line-height: 19px;
    color: rgba(255, 255, 255, 0.7);
    padding-bottom: 45px;
    a {
      color: $yellow-highlight;
    }
    @media (max-width: 1199px) {
      font-size: 13px;
    }
  }
}
.footer_credit_logo {
  img {
    width: 50%;
    margin-top: 50px;
  }
}

.claim_login_bonus {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 200;
  .text-big {
    margin-top: 5px;
    img {
      width: 60px;
    }
    div {
      font-size: 25px;
      vertical-align: "middle";
    }
  }
}
