$bannerBackground: #11112b;

.header_banner_area {
  overflow-x: hidden;
  background-color: $bannerBackground;
  margin: 10px 0 30px 0;
  border-radius: 25px;
  box-shadow: 0px 0px 3px #2a2a49cc;

  @media (max-width: 767px) {
    margin-bottom: 15px;
  }

  .banner_v1 {
    margin: 10px;
    @media (max-width: 767px) {
      padding-bottom: 25px;
    }
    .single_item {
      position: relative;

      &.margin_top_20_mobile {
        @media (max-width: 767px) {
          margin-top: 20px;
        }
      }
      .banner_img {
        img {
          border-radius: 20px;
          width: 100%;
          // height: 330px;

          @media (max-width: 767px) {
            width: 100%;
          }

          // @media (min-width: 768px) and (max-width: 1199px) {
          //   height: 300px;
          // }
          // &.small {
          //   height: 155px;
          //   @media (min-width: 768px) and (max-width: 1199px) {
          //     height: 140px;
          //   }
          // }
        }
        .bigBanner {
          @media (min-width: 768px) and (max-width: 991px) {
            display: none;
          }
        }
        .midBanner {
          @media (min-width: 992px) {
            display: none;
          }
        }
      }
      .overlay_content {
        // position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: 0 15px;
        border-radius: 20px;
        @media (max-width: 768px) {
          padding: 0 30px;
        }
        // background: linear-gradient(
        //   98.87deg,
        //   #040f46 46.64%,
        //   rgba(4, 15, 70, 0) 69.17%
        // );
        filter: drop-shadow(0px 4px 28px rgba(0, 0, 0, 0.32));
      }
      .banner_text_section {
        width: 50%;
        position: absolute;
        // top: 50%;
        // transform: translateY(-50%);
        // bottom: 25px;
        padding-left: 30px;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        @media (max-width: 1340px) {
          padding-left: 15px;
        }
        @media (max-width: 767px) {
          bottom: 0px;
          padding: 10px;
          top: 0px;
          -ms-transform: unset;
          transform: unset;
        }
        .smallMsg {
          color: #fff;
          margin-bottom: 5px;
          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
        .banner_headline {
          // font-family: "Myriad Pro";
          font-style: normal;
          font-weight: 700;
          font-size: 55px;
          line-height: 40px;
          color: $white;
          position: relative;

          @media (max-width: 1199px) {
            font-size: 35px;
          }
          @media (max-width: 768px) {
            font-size: 20px;
            line-height: 25px;
          }
          &.small {
            // font-family: "Myriad Pro";
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 26px;
            color: #ffffff;
            @media (min-width: 768px) and (max-width: 1199px) {
              font-size: 16px;
              line-height: 18px;
              font-weight: 400;
            }
          }
          label {
            background: linear-gradient(
              to bottom,
              rgba(204, 95, 255, 1) 0%,
              rgba(204, 95, 255, 1) 7%,
              rgba(193, 84, 245, 1) 27%,
              rgba(158, 46, 213, 1) 71%,
              rgba(142, 20, 198, 1) 100%
            );
            font-size: 14px;
            padding: 0 15px;
            border-radius: 25px;
            position: absolute;
            left: 30%;
            top: 44px;
            text-transform: uppercase;
            height: 30px;
            line-height: 30px;
            @media (max-width: 767px) {
              font-size: 12px;
              line-height: 20px;
              height: 20px;
              top: 22px;
              padding: 0 5px;
              font-weight: 400;
            }
          }
        }
        .banner_headline_colored {
          // font-family: "Myriad Pro";
          font-style: normal;
          font-weight: 700;
          font-size: 35px;
          line-height: 35px;
          color: #fff;
          padding: 0 0 10px 0;
          @media (max-width: 1199px) {
            font-size: 25px;
          }
          @media (max-width: 768px) {
            font-size: 20px;
            line-height: 20px;
          }
        }

        .commonBtn.customeSize {
          @media (min-width: 768px) {
            width: 180px;
            line-height: 60px;
          }
          @media (max-width: 767px) {
            margin-top: 20px;
          }
        }

        .commonBtn.roundCorner {
          border-radius: 25px;
          @media (max-width: 1199px) {
            line-height: 35px;
            padding: 0 5px;
          }
        }

        .banner_description {
          // font-family: "Myriad Pro";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: $white;
        }
        .banner_link {
          margin: 15px 0 0px;
          &.small {
            margin: 20px 0 10px;
          }
          .small_banner {
            font-size: 14px !important;
            // padding: 10px 30px;
            line-height: 22px !important;
          }
          @media (max-width: 768px) {
            margin: 10px 0 10px;
          }
        }
      }
      .socialPlatform {
        padding: 0;
        margin: 15px 0 0 0;
        display: flex;
        gap: 9px;
        @media (max-width: 767px) {
          margin-top: 10px;
          gap: 5px;
        }

        li {
          list-style: none;
          background: #8e14c6;
          width: 30px;
          height: 30px;
          border-radius: 25px;
          line-height: 25px;
          text-align: center;
          cursor: pointer;

          svg {
            width: 20px;
            fill: #d552ff;
            g {
              fill: #d552ff;
            }
            path {
              fill: #d552ff;
            }
          }
          @media (max-width: 767px) {
            width: 20px;
            height: 20px;
            line-height: 15px;
            svg {
              width: 15px;
            }
            &.googleIAction {
              svg {
                width: 18px;
              }
            }
            &.telegramAction {
              svg {
                margin-left: -2px;
              }
            }
            &.youTubeAction {
              svg {
                width: 13px;
              }
            }
          }
        }
      }
    }
  }

  .single_banner_area {
    position: relative;
    .banner_bg {
      width: 100%;
      display: none;
      &.desktop_banner {
        @media (min-width: 1200px) {
          display: block;
        }
      }
      &.tab_banner {
        @media (min-width: 768px) and (max-width: 1199px) {
          display: block;
        }
      }

      &.mobile_banner {
        @media (max-width: 767px) {
          display: block;
        }
      }
    }

    .banner_text_section {
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      &.vip_banner {
      }

      .banner_headline {
        font-size: 50px;
        color: $white;
        line-height: 44px;
        font-weight: bold;
        font-family: "Roboto", sans-serif;
        text-shadow: 1px 1px 1px #000;
        @media (max-width: 767px) {
          font-size: 30px;
          line-height: 25px;
        }
        @media (max-width: 400px) {
          font-size: 20px;
          line-height: 20px;
        }
      }
      .banner_headline_colored {
        font-size: 40px;
        // line-height: 35px;
        color: $white;
        font-weight: bold;
        font-family: "Myriad Pro";
        color: #ffff00;

        @media (max-width: 767px) {
          font-size: 30px;
        }
        @media (max-width: 400px) {
          font-size: 20px;
        }

        text-shadow: 1px 1px 1px #000;
        span {
          color: $white;
        }
      }

      .banner_description {
        margin: 5px 0;
        color: $white;
        text-shadow: 1px 1px 1px #000;
        @media (max-width: 400px) {
          font-size: 12px;
        }
      }
      .common_btn {
        margin-top: 10px;
      }
      .vip_banner_text {
        font-size: 32px;
        text-transform: uppercase;
        color: $white;
        width: 500px;
        font-family: "Myriad Pro";
        color: #ffff00;
        font-weight: bold;
        text-shadow: 1px 1px 1px #000;
        // background: -webkit-linear-gradient(#f8ff51, #976118);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        @media (min-width: 768px) and (max-width: 1199px) {
          font-size: 24px;
          width: 300px;
        }
        @media (max-width: 767px) {
          font-size: 20px;
          width: 300px;
        }
      }
    }
  }
}
