$coinSwitcherBackground: rgba(55, 70, 92, 0.3);
.coinSwitcherArea {
  display: flex;
  align-items: center;
  height: 50px;
  background-color: $coinSwitcherBackground;
  gap: 10px;
  padding: 0 10px;
  border-radius: 10px;
  width: 100%;
  justify-content: space-around;

  .balanchArea {
    .sweepCoinArea {
      display: flex;
      gap: 10px;
    }
  }
  .actionArea {
  }

  .currencyToggle {
    display: inline-block;
    height: 32px;
    position: relative;
    width: 69px;
    padding: 0;
    margin: 0;
    flex: none;
    cursor: pointer;
    font-size: 0;

    input {
      display: none;

      &:not(:checked) + .switch {
        .switch__slider {
          // background-color: $yellow-dark;

          &:before {
            content: " ";
            -webkit-transform: translateX(0);
            transform: translateX(0);
            // background: -webkit-gradient(
            //   linear,
            //   left top,
            //   left bottom,
            //   from($white),
            //   to($yellow-highlight)
            // );
            // background: linear-gradient(180deg, $white, $yellow-highlight);
          }
        }

        .switch__fixed-icon--left:after {
          opacity: 1;
        }

        .switch__fixed-icon--right {
          opacity: 0.4;
        }
      }

      &:disabled + .switch {
        .switch__slider {
          background-color: $grey-50;

          &:before {
            background: $grey-20;
            background: $yellow-highlight;
          }
        }

        .switch__fixed-icon--left:after {
          opacity: 1;
        }

        .switch__fixed-icon--right:after {
          opacity: 0.2;
        }
      }
    }

    .switch__fixed-icon--left:after {
      content: url("../../../assets/sweeplay/images/cashierModal/gold-coin-sweeplay.svg");
      width: 23px;
      height: 23px;
      display: block;
      position: absolute;
      left: 4px;
      top: 4px;
      opacity: 1;
      filter: grayscale(100%);
    }

    .switch__fixed-icon--right:after {
      content: url("../../../assets/sweeplay/images/cashierModal/sweeplay-coin.svg");
      width: 23px;
      height: 23px;
      display: block;
      position: absolute;
      right: 4px;
      top: 4px;
      opacity: 1;
      filter: grayscale(100%);
    }
    .switch.GC {
      .switch__fixed-icon--left:after {
        filter: grayscale(0%);
        width: 34px;
        height: 34px;
        left: -2px;
        top: -1px;
      }
    }
    .switch.SC {
      .switch__fixed-icon--right:after {
        filter: grayscale(0%);
        width: 34px;
        height: 34px;
        right: -2px;
        top: -1px;
      }
    }
    .switch__slider {
      background-color: rgba(55, 70, 92, 0.7);
      bottom: 0;
      cursor: pointer;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 25px;

      &:before {
        border-radius: 50%;
        // background: -webkit-gradient(
        //   linear,
        //   left top,
        //   left bottom,
        //   from($white),
        //   to($green)
        // );
        // background: linear-gradient(180deg, $white, $green);
        height: 32px;
        width: 32px;
        left: 0;
        position: absolute;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        line-height: 14px;
        text-align: center;
        content: " ";
        -webkit-transform: translateX(37px);
        transform: translateX(37px);
      }
    }
  }

  .courrentBalance {
    background-color: rgba(55, 70, 92, 0.7);
    color: $textColor;
    border-radius: 10px;
    text-align: center;
    font-weight: 500;
    line-height: 30px;
    padding: 0 10px;
  }

  &.mobileHeader {
    .actionArea {
      display: none;
    }
    .courrentBalance {
      font-size: 14px;
      width: 70px;
      background-color: transparent;
      border-radius: 0;
      font-weight: normal;
      line-height: unset;
      padding: 0;
    }
  }
}
