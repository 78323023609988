$textColor: #fff;

.contentPage {
  background: $mainBackground;
  border-radius: 25px;
  padding: 25px;
  font-family: $fontFamily;
  margin: 15px 0;
  @media (max-width: 767px) {
    padding: 15px;
  }
  .pageTitle {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 63px;
    // color: $white;
    color: $headignColor;
    margin-bottom: 30px;
    @media (max-width: 1199px) {
      font-size: 30px;
    }
    @media (max-width: 767px) {
      font-size: 25px;
      line-height: 30px;
      margin-bottom: 15px;
    }
  }

  .paragraph {
    // font-family: $fontFamily;
    font-style: normal;
    // font-weight: 400;
    font-size: 14px;
    // line-height: 63px;
    // color: $white;
    color: $textColor;
    p {
      margin-bottom: 0;
      font-size: 16px;
      br {
        // display: none;
      }
      strong {
        @media (max-width: 767px) {
          font-size: 16px;
          // font-weight: 400;
        }
      }
    }
    @media (max-width: 1199px) {
      font-size: 30px;
    }
    a {
      color: orange;
    }
  }
}
