$common_text_color: #747373;

.gameItemBlock {
  position: relative;
  transition: 0.3s;
  padding: 5px;

  @media (max-width: 767px) {
    padding: 0;
  }
  // background: #02090e;
  // background: linear-gradient(90deg, #02090e 0%, rgba(9, 2, 3, 0) 100%);

  .game_head {
    display: none;
    // display: flex;
    justify-content: space-between;
    padding: 10px;
    background: linear-gradient(180deg, #d2d2d2 0%, #828282 100%);
    border-radius: 10px 10px 0 0;
    align-items: center;
    transition: 0.3s;
    .type_icon {
      display: grid;
      grid-template-columns: 1fr 4fr;
      span {
        margin-right: 5px;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #6e6e6e;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: 991px) {
          font-size: 9px;
        }
      }
    }
  }
  .provider_logo {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
  .game_thumb {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    transition: 0.3s;
    // border: 1px solid #aba8a8;

    img {
      width: 100%;
    }

    &:hover {
      .game_overlay {
        display: block;
        // transform: scale(1.01);
      }
      img {
        transition: transform 700ms;
        // transform: scale(1.01);
      }
    }
    .provider_name {
      position: absolute;
      bottom: 5px;
      left: 0;
      width: 100%;
      color: $white;
      text-align: center;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
  .game_description {
    // margin-top: 15px;
    position: absolute;
    bottom: 0px;
    z-index: 1;
    border-radius: 10px;
    // transform: rotate(-90deg);
    background: #02090e;
    background: linear-gradient(360deg, #02090e 0%, rgba(9, 2, 3, 0) 100%);

    text-align: center;
    transition: 0.3s;
    width: 100%;
    .game_title {
      padding-bottom: 15px;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      font-style: normal;
      color: #ffffff;
      text-decoration: none;
      font-family: $fontFamily;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }

  .game_overlay {
    // border-radius: 20px;
    border-radius: 0px;
    // transition: display 0.3s;
    display: none;
    padding: 5px 10px;
    text-align: center;
    background: rgba(185, 208, 238, 0.9);
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
    top: 0px;

    @media (max-width: 767px) {
      padding: 5px;
    }
    .game_name {
      text-align: center;
      // text-transform: uppercase;
      font-size: 16px;
      color: #262932;
      font-weight: 700;
      // text-decoration: underline;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 10px;
    }
    .rtp_info {
      color: $white;
      display: grid;
      grid-template-columns: 80% 20%;
      align-items: center;
      justify-content: space-between;
      .rtp_text {
        color: #262932;

        text-align: left;
        font-size: 12px;
        padding-top: 4px;
        font-weight: 700;
        // span {
        //   color: orange;
        // }
      }

      svg {
        width: 15px;
        fill: #262932;
        cursor: pointer;
      }
    }
    .favorite_icon {
      // position: absolute;
      right: 10px;
      top: 10px;
      // width: 40px;
      // height: 40px;
      // background-color: #a87fff;
      // background-image: linear-gradient(115deg, #a87fff 0%, #7230ff 100%);
      // border: 1px solid #7230ff;
      // border-radius: 25px;
      // line-height: 35px;
      text-align: center;
      svg {
        path {
          fill: #7230ff;
        }
      }
    }
    .game_info {
      position: absolute;
      left: 0;
      bottom: 5px;
      // display: grid;
      grid-template-columns: 80% 20%;
      width: 100%;
      // padding: 0 10px;
      text-align: center;
      display: grid;
      grid-template-columns: 1fr 30px;
      align-items: center;
      margin-top: 5px;
      height: 25px;
      .right {
        // display: none;
      }
      text-align: center;

      .game_provider_name {
        text-transform: uppercase;
        font-size: 10px;
        text-align: center;
        width: 100%;
        // margin-top: 5px;
        color: #262932;
        // color: $color_two;
        font-style: normal;
        margin-bottom: 0px;
        @media (max-width: 767px) {
          display: none;
        }
      }
      .favorite_icon {
        svg {
          fill: #262932;
          path {
            fill: #262932;
          }
        }
      }
    }

    .game_action {
      // position: absolute;
      // top: 35%; /* position the top  edge of the element at the middle of the parent */
      // left: 50%; /* position the left edge of the element at the middle of the parent */
      // transform: translate(-50%, -50%);
      // margin: auto;
      background-color: #6eb514;
      padding-bottom: 3px;
      border-radius: 10px;
      height: 35px;
      width: 80%;
      margin: 0 auto;

      @media (max-width: 1340px) {
        bottom: 0px;
        width: 100%;
      }

      .game_play_action {
        // border: 2px solid var(--landing-color);
        // min-width: 100px;
        height: 30px;
        line-height: 25px;
        border: 1px solid $white;
        color: $white;
        /* display: -webkit-box; */
        display: -ms-flexbox;
        /* display: flex; */
        margin-bottom: 10px;
        font-size: 14px;
        margin: 0 auto;
        text-align: center;
        display: block;
        border-radius: 3px;
        @media (max-width: 425px) {
          height: 25px;
          line-height: 20px;
          margin-bottom: 5px;
        }

        &:hover {
          background-color: $white;
          text-decoration: none;
          color: #fff;
          transform: translate(0, -3px);
        }
      }
      .demo_game_play_action {
        display: block;
        background-color: transparent;
        border: 1px solid $white;
        color: $white;
        font-size: 12px;
        min-width: 100px;
        height: 30px;
        margin: 10px auto;
        line-height: 24px;
        border-radius: 3px;

        @media (max-width: 425px) {
          height: 25px;
          margin: 5px auto;
          line-height: 20px;
        }

        &:hover {
          background-color: $white;
          text-decoration: none;
          color: #fff;
        }
      }

      .game_play_icon {
        // width: 50px;
        // height: 50px;
        display: grid;
        grid-template-columns: 50px 1fr;
        height: 35px;
        text-decoration: none;
        // line-height: 52px;
        // background-color: white;
        border-radius: 15px;
        text-align: center;
        justify-content: center;
        align-items: center;
        // padding-left: 2px;
        // padding-bottom: 5px;
        // background: linear-gradient(
        //   180deg,
        //   var(--landing-color) 11.77%,
        //   #172b54 185.64%
        // );

        // box-shadow: 0 3px 20px #109aa963, inset 0 4px 4px #ffffff5e;
        span {
          font-weight: 700;
          color: #fff;
          text-align: left;
        }

        .fas {
          color: #fff;
        }

        &:hover {
          // background-color: $buttonColorOne;
          // transform: translate(0, -3px);
          background: linear-gradient(
            360deg,
            var(--landing-color) 11.77%,
            #172b54 185.64%
          );
          .fas {
            color: orange;
          }
        }
      }
      &:hover {
        padding-bottom: 5px;
      }
    }
  }
  &:hover {
    .game_thumb {
      transform: scale(1.05);
    }
    .game_head {
      transform: translate(0, -12px);
    }
    .game_overlay {
      transform: scale(1.01);
    }
    .game_description {
      transform: scale(1.01);
    }

    @media (max-width: 767px) {
      top: -20%;
      &:nth-child(3n + 2) {
        left: -10%;
      }
      .game_thumb {
        // transform: scale(1.05);
        z-index: 11;
        width: 150px;
        height: 120px;
        position: absolute;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);
        img {
          height: 100% !important;
        }
      }
      &:nth-child(3n + 0) {
        .game_thumb {
          right: 0px;
        }
      }

      .game_head {
        transform: translate(0, -12px);
      }
      .game_overlay {
        transform: scale(1.01);
      }
      .game_description {
        transform: scale(1.01);
      }
    }

    // .game_description {
    //   transform: translate(0, 5px);
    //   .game_title {
    //     color: $blue !important;
    //   }
    // }
  }
}
// Game Slider Style
$menuHoverColor: #ff6b2c;
.gameSlider {
  .gameSliderHeading {
    // display: flex;
    justify-content: space-between;
    align-items: center;
    .headline_text {
      font-size: 28px;
      color: $textColor;
      font-weight: 700;
      @media (max-width: 575px) {
        font-size: 22px;
      }
    }
    .gameAction {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;

      .slideAction {
        display: flex;
        align-items: center;
        gap: 10px;
        .action_btn {
          background-color: $headerBlockBackground;
          text-align: center;
          line-height: 0px;
          border: 0px;
          box-shadow: 0px;
          border-radius: 12px;
          height: 35px;
          width: 35px;
          padding: 0;
          cursor: pointer;
          @media (max-width: 575px) {
            margin-left: 20px;
          }
          &:focus {
            border: none !important;
            box-shadow: none !important;
          }
          &:hover {
            background-color: $menuHoverColor;
          }
        }
      }

      .items {
        display: inline-block;
        color: #eeeeee;
        background: none;
        border: none;
        text-transform: uppercase;
        font-size: 14px;

        margin: 0 10px;
        @media (max-width: 1199px) {
          margin: 0;
        }
        &.active {
          // color: $theme_color_orange;
        }
        &.casino {
          &.active {
            // color: $theme_color_green;
          }
        }
        &.poker {
          &.active {
            // color: $theme_color_poker;
          }
        }
        &.sport {
          &.active {
            // color: $theme_color_sport;
          }
        }
      }
      .bullet_icon_faq {
        height: 14px;
        width: 12px;
        &:nth-last-child(1) {
          display: none;
        }
      }
    }
  }
  .slick-list {
    overflow-y: visible;
    @media (max-width: 767px) {
      overflow: visible !important;
      .gameItemBlock {
        padding: 0 5px;
      }
    }
  }
}

// Game Menu Style

.gameFilterAreas {
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
  grid-gap: 10px;
  align-items: center;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    overflow-x: auto;
  }

  &.callFromSlider {
    display: block;
  }
}

.gameMenu {
  padding: 0;
  margin: 15px 0;
  display: flex;
  flex-flow: row;
  gap: 10px;
  li {
    list-style: none;
    // width: 65px;
    background-color: #11112b;
    border-radius: 10px;

    a {
      color: $textColor;
      text-decoration: none;
      font-weight: 500;
      // display: flex;
      align-items: center;
      gap: 5px;
      text-align: center;
      padding: 0 15px;
      display: block;
      .svg_icon {
        fill: $textColor;
      }
      .icon {
        // background: rgba(186, 74, 234, 0.5);
        // background: linear-gradient(
        //   180deg,
        //   rgba(186, 74, 234, 0.5) 20%,
        //   rgba(143, 32, 182, 0.5) 79%
        // );
        width: 60px;
        height: 55px;
        // border-radius: 10px;
        text-align: center;
        line-height: 58px;
        margin: 0 auto;
        img {
          width: 35px;
        }
        &.logoIcon {
          // background: unset;
          img {
            width: 25px;
          }
        }
      }
      .text {
        font-size: 10px;
        text-transform: uppercase;
        // margin-top: 5px;
        margin-bottom: 10px;
      }

      &.active,
      &:hover {
        color: $menuHoverColor;
        // .icon {
        //   background: rgba(186, 74, 234, 1);
        //   background: linear-gradient(
        //     180deg,
        //     rgba(186, 74, 234, 1) 20%,
        //     rgba(143, 32, 182, 1) 79%
        //   );
        // }
        .svg_icon {
          fill: $menuHoverColor;
        }
      }
    }
    &:hover {
      background: rgba(186, 74, 234, 1);
      background: linear-gradient(
        180deg,
        rgba(186, 74, 234, 1) 20%,
        rgba(143, 32, 182, 1) 79%
      );
    }
  }
}

.gamesLists {
  margin: 30px 0;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  grid-gap: 10px;
  &.video_poker {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 575px) {
    margin: 20px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 10px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 10px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (min-width: 992px) and (max-width: 1320px) {
    grid-template-columns: repeat(5, 1fr);
  }
}

//Game Play style
$gamePlayBackground: #11112b;
$textColor: #fff;
$hoverBg: linear-gradient(180deg, #3d4055 0%, rgba(51, 54, 71, 0) 127.81%);
.game_play {
  margin: 10px 0;
  background: $gamePlayBackground;

  // background: linear-gradient(180deg, #3d4751 0%, #29333d 100%);
  border-radius: 25px;
  padding: 30px;
  .game_content {
    .game_head {
      border-radius: 25px 25px 0px 0px;
      background: $hoverBg;
      color: $heading_color_v1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      padding: 15px;
      .game_title {
        color: $textColor;
      }
    }
    height: 728px;
    // @media (max-width: 1199px) {
    //   border-radius: 15px 15px 0 0;
    // }

    img {
      cursor: pointer;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      box-shadow: 0px 0px 25px $black;
    }
    img.placeholder {
      height: auto;
      opacity: 0;
    }
    iframe {
      // position: absolute;
      // top: 0;
      // left: 0;
      // z-index: 20 !important;
    }
  }
  .frame_bottom {
    background: $hoverBg;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    border-radius: 0 0 25px 25px;
    align-items: center;
    margin-top: 48px;
    .actions_icon {
      display: flex;
      gap: 20px;
      padding-right: 20px;
      color: $common_text_color;
      align-items: center;

      .searchbar_searchbox {
        background: none !important;
        .search-icon {
          color: #b1c1d3 !important;
        }
        input {
          background: none !important;
          width: 55px;
          color: #7d8386;
          &::placeholder {
            color: transparent;
          }
          &:focus {
            width: 100%;
            &::placeholder {
              color: #7d8386;
            }
          }
        }
      }

      .currency_select {
        background: #2f4553;
        color: #b1bad3;
        padding: 3px 15px;
        border: none;
        outline: none;
        box-shadow: none;
        border-radius: 15px;
      }
      i {
        cursor: pointer;
      }
      a {
        color: $common_text_color;
      }
    }
    .text_area {
      color: $textColor;
      font-size: 20px;
      font-family: $fontFamily;
    }

    .actions_btn {
      color: $textColor;

      .custom-control-input:checked ~ .custom-control-label::before {
        color: $textColor;
        border-color: #008a01;
        background-color: #008a01;
      }
      .custom-control-input {
        border: none;
        &:focus {
          outline: none !important;
          box-shadow: none !important;
        }
      }

      .home_link {
        svg {
          width: 20px;
          height: 20px;
          fill: $textColor;
          &:hover {
            fill: orange;
          }
        }
      }
    }
  }
  .game_badge {
    .accordion {
      background: #0f212e !important;
      border-radius: 5px !important;
      .accordion-item {
        background: #0f212e !important;
        border-radius: 5px !important;
        .accordion-header {
          .accordion-button {
            display: block !important;
            background: #0f212e !important;
            background-color: #0f212e !important;
            &::after {
              display: none !important;
            }
            &:focus {
              box-shadow: none !important;
            }
            .header_flex {
              display: flex !important;
              justify-content: space-between !important;
              .text_area {
                color: $white;
                span {
                  color: #7d8386 !important;
                  margin-left: 15px !important;
                }
              }
              .icon_area {
                color: #b1bad3;
                background: #2f4553;
                padding: 5px 15px;
                border-radius: 8px;
                display: flex;
                gap: 5px;
                font-size: 12px;
                align-items: center;
                .golden {
                  color: #cfc36e !important;
                }
              }
            }
          }
        }
        .accordion-body {
          .tabs {
            display: flex;
            border-radius: 3rem;
            padding: 5px;
            flex-shrink: 0;
            background: #1a2c38 !important;
            width: fit-content;
            gap: 15px;
            li {
              list-style: none;
              cursor: pointer;
              padding: 10px 15px;

              text-align: center;
              border-radius: 3rem;
              color: $white;
              font-size: 13px;
              &:hover {
                background: #2f4553;
              }
              &.active {
                background: #2f4553;
              }
            }
          }
          .description {
            display: flex;
            gap: 20px;
            .game_img {
              img {
                width: 200px;
                border-radius: 10px;
              }
            }
            .details {
              .lavels {
                li {
                  list-style: none;
                  width: fit-content;
                  margin-right: 10px;
                  float: left;
                  color: #b1bad3;
                  background: #2f4553;
                  padding: 0px 8px;
                  border-radius: 10px;
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }
  }
  &.theaterModeEnabled {
    width: 70%;
    height: auto;
    margin: 0 auto;
  }

  .game_details {
    background: #282829;
    border-radius: 15px;
    padding: 15px 15px 0 15px;
    height: 100%;

    @media (min-width: 501px) and (max-width: 1199px) {
      display: flex;
      justify-content: space-around;
    }

    @media (max-width: 1199px) {
      border-radius: 0 0 15px 15px;
    }
    .headiing {
      font-size: 20px;
      color: #f9f9f9;
      line-height: 1;
    }
    .single_details_block {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 15px 0;

      &.players {
        align-items: center;
        grid-template-columns: unset;
        .item {
          border-right: none;
        }
      }
      .item {
        padding: 5px;
        border-right: 1px solid #4d5c79;
        text-align: center;
        @media (max-width: 500px) {
          border: none;
          // text-align: left !important;
        }
        &.active {
          // background: $theme_color_light;
          .item_name {
            color: $white;
          }
          .item_value {
            color: $white;
          }
        }
        &:nth-child(1) {
          padding-left: 0;
          text-align: left;
        }
        &:nth-last-child(1) {
          padding-right: 0;
        }
        .game_block {
          display: flex;
          align-items: center;

          .game_thumb {
            img {
              width: 60px;
              height: 40px;
              border: 2px solid #374869;
              border-radius: 5px;
            }
          }
          .game_name {
            font-size: 16px;
            color: $white;
            padding-left: 10px;
            align-items: center;

            .level {
              height: 20px;
              width: 20px;
              display: block;
              border-radius: 50%;
              margin-left: 10px;
              font-size: 14px;
              // color: $dark_blue_bg;
              text-align: center;
              // background: $theme_color_light;

              .supper {
                color: $white;
              }
            }
          }
        }

        .item_name {
          color: #bdbdbd;
          font-size: 14px;
          font-weight: 600;
          line-height: 1;
          &.white {
            color: $white;
          }
        }
        .item_value {
          color: #616672;
          font-size: 13px;
        }
      }
    }
  }
}

.gameLoadingProgress {
  .games_count_progress {
    height: 5px;
    margin-top: 10px;
    background-color: #282829;
    .progress-bar {
      background: linear-gradient(115deg, #a87fff 0%, #7230ff 100%);
    }
  }
}

.popover-header {
  color: #ccc !important;
}
