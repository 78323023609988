.fortune_wheel_modal {
  .modal-dialog {
    @media (min-width: 1200px) {
      width: 1200px !important;
      max-width: unset !important;
    }
    @media (min-width: 768px) and (max-width: 1199px) {
      width: 725px !important;
      max-width: unset !important;
    }
  }
}

@keyframes red-orange-blink {
  0% {
    background: linear-gradient(rgb(248, 85, 21), rgb(143, 70, 41)) padding-box;
  }
  50% {
    background: linear-gradient(90deg, #f1980c 25%, #f1980c 100%);
  }
  100% {
    background: linear-gradient(rgb(248, 85, 21), rgb(143, 70, 41)) padding-box;
  }
}

.fortune_status {
  background: #11112b !important;
  border-radius: 10px;
  padding: 5px;
  margin: 5px 0;
  font-family: "Myriad Pro";
  font-style: normal;
  .title {
    font-weight: 700;
    font-size: 25px;
    color: #fed44c;
    text-align: center;
  }
  .fortune_details {
    display: flex;
    gap: 5px;
    .fortune_icon {
      width: 65px;
      img {
        width: 100%;
      }
    }
    .fortune_text {
      width: calc(100% - 65px);
      .subtitle {
        font-size: 20px;
        color: #ffffff;
      }
      .available {
        font-size: 17px;
        color: rgb(95, 215, 95);
      }
    }
  }
  .fortune_button {
    text-align: center;
    button {
      animation: red-orange-blink 1s 0.65s ease-out infinite !important;
      width: 80%;
    }
  }
}

.reward_system {
  .claim_countdown {
    color: white;
    margin-top: 10px;
    text-align: center;
  }
  .lucky_wheel {
    background: #0c1f79;
    border-radius: 20px;
    margin-top: 40px;
    padding: 30px 0 0;
    .wheel_top_content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 1199px) {
        display: block;
        text-align: center;
      }
      .item {
        img {
          @media (max-width: 767px) {
            width: 100%;
          }
          @media (min-width: 1200px) and (max-width: 1500px) {
            max-width: 400px;
          }
        }

        .left_vector {
          margin-left: -50px;
          @media (min-width: 1200px) and (max-width: 1500px) {
            max-width: 200px;
            margin-left: -30px;
          }
        }
        .right_vector {
          margin-right: -50px;
          @media (min-width: 1200px) and (max-width: 1500px) {
            max-width: 200px;
            margin-right: -30px;
          }
        }

        &.left {
          @media (max-width: 1199px) {
            display: none;
          }
        }
        &.right {
          @media (max-width: 1199px) {
            display: none;
          }
        }
      }
    }
    .claim_button {
      margin-top: 15px;
      a,
      button {
        width: 100%;
        display: block;
        max-width: 310px;
        margin: 0 auto;
        &.disabled {
          background: gray;
          cursor: default;
        }
        @media (max-width: 768px) {
          font-size: 24px;
          line-height: 40px;
        }
      }
    }
    .wheel_text_area {
      padding: 30px 60px;
      @media (max-width: 991px) {
        padding: 30px;
      }

      .headline_colored {
        font-family: "Myriad Pro";
        font-style: normal;
        font-weight: 700;
        font-size: 39px;
        line-height: 45px;
        color: #fed44c;
        text-align: center;
        @media (max-width: 991px) {
          font-size: 30px;
        }
      }
      .dsc {
        margin: 20px 0;
        font-family: "Myriad Pro";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 45px;
        text-align: center;
        color: #ffffff;
        @media (max-width: 991px) {
          font-size: 16px;
        }
        @media (max-width: 768px) {
          line-height: 24px;
        }
      }
      .progress_bar {
        margin-bottom: 35px;
        .progress_subtitle {
          color: white;
          margin-top: 10px;
        }
        .progress {
          background: #040f46;
          border-radius: 40px;
          height: 60px;

          font-family: "Myriad Pro";
          font-style: normal;
          font-weight: 700;
          font-size: 26px;
          line-height: 65px;
          text-align: center;
          color: #ffffff;
          position: relative;

          .progressLabel {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          @media (max-width: 991px) {
            font-size: 18px;
          }

          @media (max-width: 768px) {
            font-size: 16px;
          }

          .progress-bar {
            border-radius: 40px;
            background: linear-gradient(
              90deg,
              #00d7f7 5.53%,
              #0691e0 19.07%,
              #0b66d2 53.55%,
              #0789de 89.34%,
              #01ccf3 98.42%
            );
          }
        }
      }
    }
  }
  .progressLabelMobile {
    color: white;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
  }
}
