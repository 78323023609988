$yellowColor: yellow;
$mainButtonBackground: #7230ff;
$secondryButton: #ff6b2c;

.gradientBtn {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+12,5b4d86+14,967ecf+16,bd9dff+18,bb9bff+21,b693ff+23,b28efe+26,af8aff+39,9c6dff+74,9b6dff+81,9365f3+82,6a49b7+84,352564+86,ffffff+88,ffffff+100&0+0,0+12,0.19+14,1+16,1+84,0.02+86,0+88,0+100 */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 12%,
    rgba(91, 77, 134, 0.19) 14%,
    rgba(150, 126, 207, 1) 16%,
    rgba(189, 157, 255, 1) 18%,
    rgba(187, 155, 255, 1) 21%,
    rgba(182, 147, 255, 1) 23%,
    rgba(178, 142, 254, 1) 26%,
    rgba(175, 138, 255, 1) 39%,
    rgba(156, 109, 255, 1) 74%,
    rgba(155, 109, 255, 1) 81%,
    rgba(147, 101, 243, 1) 82%,
    rgba(106, 73, 183, 1) 84%,
    rgba(53, 37, 100, 0.02) 86%,
    rgba(255, 255, 255, 0) 88%,
    rgba(255, 255, 255, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.blackBtnBG {
  border-radius: 12px;
  border: 1px solid transparent;
  background: linear-gradient(#3e4057, #161721) padding-box,
    linear-gradient(to bottom, #51525f, #030304) border-box;
  // line-height: 31px;
}

.commonBtn {
  min-height: 35px;
  // height: 35px;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 34px;

  color: #fff;
  text-align: center;
  font-family: $fontFamily;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  // line-height: 24px; /* 200% */
  text-transform: uppercase;
  display: inline-block;

  text-decoration: none;
  transition: 300ms;
  border-radius: 12px;
  transition-duration: 500ms;
  transition-property: all;
  box-shadow: none;
  border: none;
  svg {
    margin-right: 10px;
  }
  &:focus {
    // scale: 0.98;
  }
  &:hover,
  &.active {
    color: $white;
    text-decoration: none;
    background-color: var(--landing-color);
    transform: translateY(-4px);
    //  bottom: 32px;
    transition-duration: 500ms;
    transition-property: all;
  }

  &.blackBtnBG {
    line-height: 32px;
  }

  &.filledBtn {
    background-color: $mainButtonBackground;
    color: #fff;
  }

  &.borderBtn {
    border: 1px solid $mainButtonBackground;
  }
  &.yellowBtn {
    background-color: $yellowColor;
    color: #55657e;
  }

  &.loginBtn {
    border: none;
  }

  &.casinoBtn {
    // background: url("../assets/images/kjb/common/casinoBtnBg.png");
    // width: 110px;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    background-color: #f1980c;
    transition-duration: 500ms;
    transition-property: all;
    &:hover {
      color: $white !important;
      box-shadow: inset 500px 0 0 0 #f41010;
      transition: color 0.7s ease-in-out, box-shadow 0.8s ease-in-out;
      transition-duration: 500ms;
      transition-property: all;
      svg {
        fill: $white;
      }
    }
    svg {
      margin-right: 5px;
    }
  }
  &.sportsBtn {
    // background: url("../assets/images/kjb/common/sportsBtnBg.png");
    // width: 90px;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    background-color: #f1980c;
    transition-duration: 500ms;
    transition-property: all;
    &:hover {
      color: $white !important;
      box-shadow: inset 500px 0 0 0 #f41010;
      transition: color 0.7s ease-in-out, box-shadow 0.8s ease-in-out;
      transition-duration: 500ms;
      transition-property: all;
      svg {
        fill: $white;
      }
    }
    svg {
      margin-right: 5px;
    }
  }

  &.promotionsBtn {
    border-radius: 12px;
    display: block;
    // background: url("../assets/images/leftSideBars/bonus-btn-bg.png");
    margin-bottom: 15px;
    height: 50px;
    line-height: 50px;
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 5fr;
  }
  &.bonusBtn {
    display: block;
    // background: url("../assets/images/leftSideBars/promotions-btn-bg.png");
    background-size: cover;
    height: 50px;
    line-height: 50px;
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 5fr;
    &.buyCryptoBtn {
      padding: 0 5px;
      display: flex !important;
      svg {
        margin-right: 5px;
        path {
          fill: white;
        }
      }
    }
    svg {
      // margin-right: 5px;
      path {
        fill: white;
      }
    }
  }

  &.bannerBtn {
    border-radius: 10px;
    // border: 1px solid #fff;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#cc5fff+0,cc5fff+7,c154f5+27,9e2ed5+71,8e14c6+100 */
    background: linear-gradient(
      to bottom,
      rgba(204, 95, 255, 1) 0%,
      rgba(204, 95, 255, 1) 7%,
      rgba(193, 84, 245, 1) 27%,
      rgba(158, 46, 213, 1) 71%,
      rgba(142, 20, 198, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

    &.buyCoinBtn {
      &:hover {
        background-color: #f1980c !important;
        background: linear-gradient(90deg, #f1980c 25%, #f1980c 100%);
      }
    }
  }

  &.orengeRedBtn {
    // background: rgb(248, 85, 21);
    // background: linear-gradient(
    //   90deg,
    //   rgba(248, 85, 21, 1) 25%,
    //   rgba(244, 16, 16, 1) 100%
    // );
    &:hover {
      background-color: #f1980c !important;
      background: linear-gradient(90deg, #f1980c 25%, #f1980c 100%);
    }
    border-radius: 12px;
    border: 1px solid transparent;
    background: linear-gradient(rgb(248, 85, 21), rgb(143, 70, 41)) padding-box,
      linear-gradient(to bottom, rgb(248, 103, 46), rgb(143, 70, 41)) border-box;
  }
  &.purpleBtn {
    // background: rgb(241, 23, 234);
    // background: linear-gradient(
    //   90deg,
    //   rgb(248, 21, 229) 25%,
    //   rgb(176, 8, 181) 100%
    // );
    &:hover,
    &.active {
      background-color: #f1980c !important;
      background: linear-gradient(90deg, #f0a62f 25%, #f1980c 100%);
      border: 1px solid #f1980c;
    }

    background-color: #a87fff;
    background-image: linear-gradient(115deg, #a87fff 0%, #7230ff 100%);
    border: 1px solid #7230ff;
  }
  &.loadMoreBtn {
    padding: 0 50px;
    cursor: pointer;
  }
  &.redBtn {
    background: #e50d30;
  }
  &.noBorder {
    border: none;
    box-shadow: none;
  }
  &.bigBtn {
    width: 150px;
    line-height: 50px;
  }
}
