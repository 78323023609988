$modalBg: #11112b;

.modal {
  .modal-dialog {
    .modal-content {
      background: $modalBg;
    }
  }
}

.greyStyleModal {
  .modal-content {
    .modal-header {
      padding: 30px;
      .modal-title {
        color: #fff;
      }
      .btn-close {
        background-color: #fff;

        &:hover {
          background-color: #f1980c;
          opacity: 1;
        }
      }
    }
    .modal-body {
      color: #fff;
      padding: 30px;
    }
  }
  &.affiliateTermsModal {
    .modal-content {
      // padding: 15px;
      height: 90vh;
      // overflow-y: auto;
      overflow: hidden;
      .affiliate_terms_area {
        height: 70vh;
        overflow-y: auto;

        a {
          color: #f1980c !important;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
        .paragraph {
          a {
            color: #f1980c !important;
            text-decoration: none;
            span {
              color: #f1980c !important;
            }
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

.freespinmodal {
  color: white;
}

.modal_close_custom,
.modal_close_reg {
  position: absolute;
  height: 40px;
  width: 40px;
  background: rgba(255, 255, 255, 0.7);
  right: 20px;
  top: 20px;
  text-align: center;
  border-radius: 8px;
  line-height: 35px;
  z-index: 1;
  cursor: pointer;
  cursor: pointer;
  svg {
    path {
      fill: #000;
    }
  }

  &:hover {
    background: #f1980c;
    opacity: 1;
  }

  &.small {
    height: 35px;
    width: 35px;
    line-height: 30px;
  }
}
