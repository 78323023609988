$text_color_v1: #ccc;

$light_block_bg: #fff;

.table_area {
  padding: 30px 0;
  //   margin: 30px 0;
  @media (max-width: 575px) {
    padding: 15px !important;
  }
  h3 {
    margin: 15px 0;
  }

  .heading_row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    color: $text_color_v1;
    font-weight: 600;
    // padding: 15px;
    font-size: 15px;
    // background: #d2d2d2;
    &.transaction_history {
      grid-template-columns: 2fr 1fr 2fr 3fr;
    }
    &.game_history {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  }
  .data_row {
    border-top: 1px solid #484747;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 3fr;
    color: $text_color_v1;
    font-weight: 400;
    padding: 5px 0;
    font-size: 14px;
    align-items: center;

    @media (min-width: 576px) and (max-width: 991px) {
      font-size: 12px;
    }
    &.transaction_history {
      grid-template-columns: 2fr 1fr 2fr 3fr;
    }
    &.game_history {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    .item {
      .table_btn {
        height: 40px;
        font-size: 13px;
        text-align: center;
        padding: 0 15px;
        // background: $theme_color_light;
        border: 1px solid #1099a9;
        color: $white;
        line-height: 40px;
        display: block;
        border-radius: 5px;
        margin: 0 5px;
        &.radius_5 {
          border-radius: 5px !important;
        }
        @media (min-width: 576px) and (max-width: 991px) {
          font-size: 12px;
        }
      }
      .action_icon {
        margin-right: 15px;
        height: 20px;
        width: 20px;
        display: inline-block;
        background: #2bc155;
        font-size: 13px;
        text-align: center;
        border-radius: 50%;
      }
      .game_img {
        width: 70px;
        height: 50px;
        border-radius: 8px;
      }
    }
  }
  .mobile_accordion {
    .accordion {
      // background: $black_bg;
      border-radius: 5px;
      margin: 10px 0;
      .accordion-item {
        margin: 10px 0;
        border: none !important;
        .accordion-header {
          .accordion-button {
            padding: 10px !important;
            color: $white;
            background: linear-gradient(180deg, #3ebdf0 0%, #0c8bbe 100%);
            &.collapsed {
              color: $white;
              &::after {
                color: $white;
              }
              &::before {
                color: $white;
              }
            }
            &:focus {
              outline: none;
              box-shadow: none;
              border: none;
            }
          }
        }
        .accordion-body {
          padding: 10px !important;
          background: $light_block_bg;
          color: $text_color_v1;
          border-radius: 0 0 10px 10px;
          .accordion_content {
            @media (min-width: 401px) {
              display: grid;
              grid-gap: 10px;
              grid-template-columns: repeat(2, 1fr);
            }
          }
        }
      }
    }
  }
  .cancelBtn {
    background: linear-gradient(160deg, #1e5128 0%, #4ea03e 100%);
    color: $grayBlack_bg;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    display: block;
    // width: 100%;
    border-radius: 4px;
    height: 35px;
    line-height: 35px;
    text-decoration: none;
    padding: 0 15px;
    border: none;
    margin: 0 auto;
    cursor: pointer;
    &:hover {
      background-color: #ff8a1e;
    }
  }
}

.common_btn.gray {
  border-radius: 4px !important;
}
.common_btn.danger {
  border-radius: 4px !important;
  background-color: red;
}
