//This are temproary.

//Background Colours for template elements
$mainContentBackground: #090c23;
$sidebarBackground: #11112b;
$headerBackgorund: #11112b;
$footerBackground: #11112b;

//Button colours
$mainButton: #7230ff;
$mainButtonGradient: #7230ff;
$secondryButton: #ff6b2c;

.body {
  background-color: $mainContentBackground;
  position: relative;
  overflow: hidden;
}

.invisible_overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 104;
  &.transparent {
    opacity: 0;
  }
}

.mainContainer {
  display: flex;
  flex-flow: row;
  overflow: hidden;
  background-color: $mainContentBackground;
  // width: 100vw;

  @media (max-width: 991px) {
    display: block;
  }

  .mainContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: calc(100vh - 100px);
    // height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    @media (max-width: 767px) {
      height: 100%;
      display: block;
    }
    @media (max-width: 767px) {
      padding-bottom: 50px;
      &.sidebarOpend {
        height: calc(100vh - 144px);
      }
    }
  }
}
