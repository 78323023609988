@keyframes spinner {
  0% {
    width: 120px;
    left: calc(50vw - 60px);
    top: calc(50vh - 60px);
    opacity: 0.5;
  }
  50% {
    width: 140px;
    left: calc(50vw - 70px);
    top: calc(50vh - 70px);
    opacity: 1;
  }
  100% {
    width: 120px;
    left: calc(50vw - 60px);
    top: calc(50vh - 60px);
    opacity: 0.5;
  }
}

.spinner_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5050;
  background: rgba(0, 0, 0, 0.6);
}

.spinner {
  position: absolute;
  z-index: 5051;
  animation-iteration-count: infinite;
  animation-name: spinner;
  animation-duration: 1s;
}
