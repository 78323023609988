@font-face {
  font-family: "Myriad Pro";
  src: url("../../assets/sweeplay/fonts/MyriadPro-Bold.woff2") format("woff2"),
    url("../../assets/sweeplay/fonts/MyriadPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Myriad Pro";
  src: url("../../assets/sweeplay/fonts/MyriadPro-BoldIt.woff2") format("woff2"),
    url("../../assets/sweeplay/fonts/MyriadPro-BoldIt.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Myriad Pro";
  src: url("../../assets/sweeplay/fonts/MyriadPro-BoldCondIt.woff2")
      format("woff2"),
    url("../../assets/sweeplay/fonts/MyriadPro-BoldCondIt.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Myriad Pro";
  src: url("../../assets/sweeplay/fonts/MyriadPro-BoldCond.woff2")
      format("woff2"),
    url("../../assets/sweeplay/fonts/MyriadPro-BoldCond.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Myriad Pro";
  src: url("../../assets/sweeplay/fonts/MyriadPro-SemiboldIt.woff2")
      format("woff2"),
    url("../../assets/sweeplay/fonts/MyriadPro-SemiboldIt.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Myriad Pro";
  src: url("../../assets/sweeplay/fonts/MyriadPro-Light.woff2") format("woff2"),
    url("../../assets/sweeplay/fonts/MyriadPro-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Myriad Pro";
  src: url("../../assets/sweeplay/fonts/MyriadPro-Regular.woff2")
      format("woff2"),
    url("../../assets/sweeplay/fonts/MyriadPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Myriad Pro";
  src: url("../../assets/sweeplay/fonts/MyriadPro-Cond.woff2") format("woff2"),
    url("../../assets/sweeplay/fonts/MyriadPro-Cond.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Myriad Pro";
  src: url("../../assets/sweeplay/fonts/MyriadPro-CondIt.woff2") format("woff2"),
    url("../../assets/sweeplay/fonts/MyriadPro-CondIt.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Myriad Pro";
  src: url("../../assets/sweeplay/fonts/MyriadPro-Semibold.woff2")
      format("woff2"),
    url("../../assets/sweeplay/fonts/MyriadPro-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Franklin Gothic";
  src: url("../../assets/sweeplay/fonts/FranklinGothic-Heavy.eot");
  src: url("../../assets/sweeplay/fonts/FranklinGothic-Heavy.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/sweeplay/fonts/FranklinGothic-Heavy.woff2")
      format("woff2"),
    url("../../assets/sweeplay/fonts/FranklinGothic-Heavy.woff") format("woff"),
    url("../../assets/sweeplay/fonts/FranklinGothic-Heavy.ttf")
      format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
