.sticky_footer_menu_area {
  z-index: 105;
  position: sticky;
  bottom: -1px;
  .sticky_search_area {
    // border: 1px solid $first_color;
    // background-color: $bg_color;
    height: 40px;
    padding: 5px 10px;
    display: none;
    transition: all 3s;
    input {
      border-radius: 8px;
      // background: $input_bg_color;
      color: $white;
      height: 30px;
      // transition: visibility 0s linear 700ms, opacity 700ms;

      // font-family: $font;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 30px;
      width: 100%;
      padding: 0 10px;
      border: none;
      &:focus {
        border: none;
        outline: none;
      }
    }
    &.showSearch {
      display: block;
    }
  }
  ul {
    border-top: 1px solid #7230ff;
    background-color: #11112b;
    padding: 0px;
    margin: 0px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    text-align: center;
    li {
      padding: 5px 0 15px 0;
      list-style: none;
      color: $white;
      // line-height: 40px;
      a {
        color: $textColor;
        .svg_icon {
          fill: $textColor;
        }
        .menu_text {
          font-size: 11px;
          // font-family: $font;
          line-height: 10px;
        }
        &:hover {
          color: orange;
          text-decoration: none;
          .svg_icon {
            fill: orange;
          }
        }
      }
      &:hover {
        color: $gold-color;
      }
    }
  }
}

//Sticky Menu
.stickyMenu {
  padding-top: 20px;
  position: fixed;
  width: 100%;
  bottom: 0px;
  z-index: 300;
  .nav-bar {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    justify-content: space-around;
    align-items: center;
    // background-color: rgba(16, 36, 68, 1);
    background-color: #1e222f;
    padding: 0px 0 0px 0;
    // height: 60px;
    overflow: visible;
  }

  .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    font-size: 12px;
    text-align: center;
    text-decoration: none;
    padding: 3px 0;
    margin: 5px;
    .icon {
      width: 25px;
      height: 25px;
      border-radius: 25px;
      // background-color: #1e1b39;
      line-height: 27px;
      img {
        width: 25px;
      }
      svg {
        fill: #fff;
        path {
          fill: #fff;
        }
      }
    }
    &.middleBtn {
      position: relative;
      &:hover {
        background: none;
      }
      .middleItem {
        border-radius: 50%;
        width: 45px;
        height: 45px;
        line-height: 45px;
        box-shadow: orange 1px 1px 15px 5px;
        border: 2px solid orange;
        background-color: orange;
        margin-top: -24px;
        .icon {
          margin: 0 auto;
          line-height: 39px;
          width: 42px;
          height: 42px;
          img {
            width: 41px;
            // margin-top: -2px;
            // margin-left: -3px;
          }
        }
        span {
          display: none;
        }
      }
    }
  }

  .nav-item span {
    margin-top: 3px;
  }

  .nav-item:hover,
  .nav-item.active {
    // color: orange;
    background-color: #3a3f4b;
    border-radius: 10px;

    .icon {
      // background-color: orange;
      color: #000;
      svg {
        // fill: white !important;
        path {
          fill: rgba(255, 255, 255, 1) !important;
        }
      }
    }
  }
}
