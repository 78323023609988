$theme_color_deep: #1d3b6a;
$theme_color_light: #504f50;
$theme_color_orange: orange;

.floatingSupportBtn {
  position: fixed;
  right: 30px;
  bottom: 30px;
  // animation: animation-shock 1s ease-out infinite;

  box-shadow: 0px 0px 10px 5px #e4964e80;
  border-radius: 50%;
  padding: 5px;
  border: 2px solid #e4964e;
  @media (max-width: 767px) {
    bottom: 75px;
  }

  &:hover {
    box-shadow: 0px 0px 10px 5px #986f48;
    border: 2px solid #eca96b;
  }
}

.support_panel {
  // width: 330px;
  // position: fixed;
  // top: 0;
  right: -400px;
  bottom: 80px;
  // height: 100vh;
  z-index: 5000;
  padding: 20px 20px 20px 20px;
  // background: $black;
  // background: linear-gradient(90deg, rgb(15, 19, 25), rgb(6, 9, 14));
  // background-image: url("../assets/images/new-landing/footer-bg.jpg");
  transition: all 0.3s;
  // overflow-y: auto;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
  // border-left: 2px solid $theme_color_orange;

  @media (max-width: 767px) {
    max-width: 100vw;
  }
  &.open {
    right: 0;
  }
  .close_icon {
    position: absolute;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    // background: #454568;
    color: $white;
    font-size: 20px;
    text-align: center;
    line-height: 40px;
    right: 20px;
    top: 20px;
    cursor: pointer;
    &:hover {
      color: $theme_color_deep;
      animation-name: spin;
      animation-duration: 1000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }

  .tabs {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 5px;

    a {
      display: block;
      text-align: center;
      border-radius: 5px 5px 0 0;
      line-height: 35px;
      width: 100%;
      text-decoration: none;
      color: $white;
      background-color: grey;
      &.active {
        background: $theme_color_orange;
        // background: -webkit-gradient(linear, left top, left bottom, from($theme_color_deep), to($theme_color_light));
        // background: linear-gradient(to bottom, $theme_color_deep 0%, $theme_color_light 100%);
        // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$theme_color_deep', endColorstr='$theme_color_light',GradientType=0 );
      }
    }
  }

  &.floating {
    position: absolute;

    // top: 50%;
    height: 420px;
    background-color: #1e1b39;

    position: fixed;
    bottom: 80px;
  }

  .panel_header {
    color: $white;
  }
  .support_sidebar_content {
    width: 100%;
    height: calc(100% - 100px);
    padding-top: 15px;

    .email_us {
      color: $white;
      padding: 15px 0;
      .contact_us_area {
        margin-top: 15px;
        .reg_field {
          margin-bottom: 15px;
          input,
          textarea {
            box-shadow: none;
            &:focus {
              border: 1px solid $theme_color_light;
            }
          }
        }
      }
    }

    .support_chat {
      width: 100%;
      height: 380px;
    }
  }
  h3 {
    color: $textColor;
  }
}

//My Tickets
.my_tickets_area {
  margin-top: 20px;
  .header {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
  }
  .accordion .accordion-header .accordion-button {
    a {
      color: $white;
      text-decoration: none;
    }
  }
  .accordion .accordion-header .accordion-button:not(.collapsed) {
    a {
      color: #000;
    }
  }
}
