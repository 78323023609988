.languageSwitcherAccordion {
  background-color: #06274b00;
  .accordion-button,
  .accordion-button:not(.collapsed) {
    background-color: #06274b00;
  }

  .accordion-button {
    outline: 0;
    box-shadow: none;
    &::after {
      background-image: url("../../../assets/sweeplay/images/languageSwitcherAccordion/arrow-down-solid.svg");
      background-position: center;
      background-size: 10px;
      // background-color: #1375e0;
      border-radius: 25px;
      line-height: 16px;
      scale: 1.5;
    }
  }
  .accordion-button:not(.collapsed) {
    outline: 0;
    box-shadow: none;
    // box-shadow: none;
    &::after {
      rotate: 0deg;
      background-image: url("../../../assets/sweeplay/images/languageSwitcherAccordion/arrow-down-solid.svg");
      background-position: center;
      background-size: 10px;
      // background-color: #1375e0;
      border-radius: 25px;
      line-height: 16px;
    }
  }
  .accordion-item {
    background-color: #06274b00;
  }
  .headingArea {
    display: flex;
    grid-gap: 10px;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .text {
      font-family: $fontFamily;
      font-size: 15px;
      font-weight: 500;
      line-height: 25px;
      font-style: normal;
      text-align: left;
      color: #ffffff;
      display: block;
    }
  }
  .languageItemLists {
    padding: 0;
    margin: 0px;
    li {
      padding: 10px 1.2rem 5px 1.2rem;
      line-height: 30px;
      display: grid;
      grid-template-columns: 80% 20%;
      cursor: pointer;
      label {
        font-family: $fontFamily;
        font-size: 15px;
        font-weight: 500;
        line-height: 25px;
        font-style: normal;
        text-align: left;
        color: $textColor;
        margin-bottom: 0px;
      }
    }
  }
}
