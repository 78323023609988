$headerBackgorund: #11112b;
$headerBlockBackground: #1e1b39;
$headerMenuTextColor: #fff;
$headerBlockBG: rgba(55, 70, 92, 0.3);

.headerComponent {
  padding: 10px;
  position: sticky;
  z-index: 105;
  .headerContent {
    border-radius: 10px;
    // height: 70px;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 2fr;
    background-color: $headerBackgorund;
    padding: 10px;
    @media (max-width: 768px) {
      grid-template-columns: 1fr 2fr;
      grid-gap: 10px;
    }
    .logoMenubar {
      display: flex;
      gap: 10px;
      width: 280px;
      justify-content: space-between;
      align-items: center;
      transition: all 500ms;
      @media (max-width: 768px) {
        width: 85px;
        gap: 5px;
      }
      .logo {
        img {
          height: 40px;
          @media (max-width: 768px) {
            height: 35px;
          }
        }
      }
      &.leftSidebarOpened {
        @media (min-width: 768px) {
          width: 300px;
        }

        .sideMenuBar {
          svg {
            rotate: 180deg;
          }
        }
      }
    }
    .highlightedCta {
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        display: none;
      }
      ul {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        gap: 15px;
        white-space: nowrap;
        li {
          list-style: none;
          a {
            display: flex;
            align-items: center;
            gap: 5px;
            text-decoration: none;
            font-weight: 500;
            color: $headerMenuTextColor;
            transition: color 300ms;
            svg,
            svg path {
              fill: $headerMenuTextColor;
              transition: fill 300ms;
            }
            &:hover {
              color: #cc5fff;
              svg {
                fill: #cc5fff;
                path {
                  fill: #cc5fff;
                }
              }
            }
          }
        }
      }
    }
    .userCta {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      ul {
        padding: 0;
        margin: 0;
        display: flex;
        gap: 15px;
        li {
          list-style: none;
          a {
          }
        }
      }
      .loggedIn {
        display: flex;
        gap: 10px;

        .userActions {
          display: flex;
          align-items: center;
          gap: 10px;
          background-color: $headerBlockBG;
          padding: 0 10px;
          border-radius: 10px;
        }
      }
    }
  }
}
