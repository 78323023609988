/* ----------------------------------------------
 * Generated by Animista on 2024-7-27 18:1:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}
.slide-bottom {
  -webkit-animation: slide-bottom 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    infinite both;
  animation: slide-bottom 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite
    both;
}

@-webkit-keyframes animation-shock {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  40% {
    -webkit-transform: scale(1.08);
    transform: scale(1.08);
  }
  50% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
  }
  55% {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
  60% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
  }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes animation-shock {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  40% {
    -webkit-transform: scale(1.08);
    transform: scale(1.08);
  }
  50% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
  }
  55% {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
  60% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
  }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes animation-wave {
  0% {
    box-shadow: 0 0 2px #faca46a6, inset 0 0 1px #faca46f2;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  95% {
    box-shadow: 0 0 16px #faca4626, inset 0 0 16px #faca4626;
  }
  to {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
}
@keyframes animation-wave {
  0% {
    box-shadow: 0 0 2px #faca46a6, inset 0 0 1px #faca46f2;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  95% {
    box-shadow: 0 0 16px #faca4626, inset 0 0 16px #faca4626;
  }
  to {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
}

.registerBtn {
  -webkit-animation: animation-shock 1s ease-out infinite;
  animation: animation-shock 1s ease-out infinite;
  &::before {
    -webkit-animation: animation-wave 1s 0.65s ease-out infinite !important;
    animation: animation-wave 1s 0.65s ease-out infinite !important;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 12px;
  }
  &::after {
    -webkit-animation: animation-wave 1s 0.65s ease-out infinite !important;
    animation: animation-wave 1s 0.65s ease-out infinite !important;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 12px;
  }
}
