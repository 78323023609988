$header_bg: rgba(14, 26, 57, 0.5);
$footer_bg: #1a1a1a;
$font: "Poppins", sans-serif;
$heading_font: "Jost", sans-serif;
$white: rgb(255, 255, 255);
$grayBlack_bg: #1a1a1a;
$bg_color: #06060e;

$light_blue: #007bff;

$level-one-nav-height: 32px;
$level-one-nav-height-lg: 50px;
$top-hud-height: 58px;

$iframe-desktop-height: 750px;
// this number is locked into the iframe contract all games have been built around this
$mobile-game-scale-factor: 2;

// Chumba
$dark-red: #c01b1b;
$bg-blue-light: #1e2325;
$bg-blue-medium: #192e49;
$bg-blue-grey: #193a66; //Used in the notifications tray.
$bg-blue-dark: #0d1d32;
$grey-light: #f5f5f5;
$grey-medium: #c6c6c6;
$banner-blue: #2c4464;
$scroll-bar-thumb: #476185;
$link-color: #007bff;
$dark-border: #4e4e4e;
$blue-highlight: #0cf;
$green-highlight: #5eff00;
$list-container-padding: 28px;
$scroll-bar-size: 17px;
$modal-box-shadow: 0 3px 4px rgba(13, 29, 50, 0.1);


// Bootstrap overrides
$red: #ff2626;
$orange: #ff6d00;
$yellow: #ffc600;
$green: #4ac900;
$blue: #0c1b46;
$enable-gradients: true;
$body-bg: $bg-blue-dark;
$body-color: $white;

$btn-focus-width: 0;
$table-color: $white;

$brand-red-gradient: linear-gradient(
  180deg,
  #ff6e6e 0.65%,
  #ff2626 51.27%,
  #c01b1b 100%
);

$brand-green-gradient: linear-gradient(
  180deg,
  #5eff00 0%,
  #4ac900 50.94%,
  #3ac200 100%
);

$grey-blue: #193a66;
$grey-20: #ccc;
$grey-50: #808080;

$yellow-highlight: #d9e9a8;
$yellow-dark: #e5a200;

$jackpot-green: #5eff00;

//TwoKBlockedModal
$white: #fff;
$very-dark-grey: #111;
$black: #000000;

// Challenge variables
$challenges-header-background-gradient: linear-gradient(
  158.69deg,
  $yellow -17.71%,
  $yellow 49.16%,
  $yellow-highlight 100.09%
);
$challenges-header-border-gradient: linear-gradient(
  180deg,
  #ffec00 2.78%,
  #ffc600 48.86%,
  #ffc600 100%
);

$challenge-modal-background-gradient: linear-gradient(
  180deg,
  #0c1b46 0%,
  rgba(12, 27, 70, 0) 110.83%
);

$max-challenge-popout-width: 536px;
$gradient-darkest-blue: #0c1b46;
$challenge-blue-border: #193a66;
$gradient-medium-blue: #094875;
$gradient-lightest-blue: #00ccff;
$challenges-border-box: 0 4px 4px rgba(0, 0, 0, 0.25);
$challenges-gradient: linear-gradient(360deg, #00ccff -115.13%, #0c1b46 47.54%);
$challenges-card-gradient: linear-gradient(
  360deg,
  $gradient-lightest-blue -7.44%,
  $gradient-medium-blue 55.15%,
  $gradient-darkest-blue 92.35%
);

// Responsible Gameplay / Rebrand variables
$rebrand-bg-white: #f4f4f4;
$rebrand-green-primary: #1cb15f;

$main_color: #4ea03e;
$main_color_deep: #2c6421;
$bottom_banner_bg: #57578f;
$view_more_bg: #2a2a44;
$modal_bg_color: #2a2a44;
$input_bg_color: #19192c;

$login_area_text_color: #bebedb;

$profile_page_text_color: #dddff8;

// ================================================
$width: 14.063em;

$benner-gradient: linear-gradient(160deg, #1e5128 0%, #4ea03e 100%);
$gradient: linear-gradient(160deg, #1e5128 0%, #4ea03e 100%);
$Light-green: rgb(78, 160, 62);
$extra-green: rgb(30, 82, 41);
$review-gradient: linear-gradient(-5deg, #4ea03e 0%, #1e5128 100%);

$custom-hover-color: #d9e9a8;


$mainBackgroundColor: #4ea03e;
