$hoverBg: linear-gradient(180deg, #6d6b6c 0%, rgba(60, 62, 74, 0) 127.81%);
$light_block_bg: linear-gradient(180deg, #efefef 0%, #dfdfdf 100%);
$text_color_v1: #666666;
$heading_color_v1: #1f2933;
.winners_block {
  .tabs {
    margin-top: 30px;
    margin-bottom: 10px;
    .commonBtn.active {
      transform: translateY(0px);
    }
  }
  .headline_text {
    font-size: 20px;
    color: $textColor;
    font-weight: 700;
  }
  .winners {
    overflow: hidden;
    .winners-slider {
      width: 4000px;
      display: flex;
      gap: 18px;
      .item {
        animation: item 0.5s linear forwards;
        -webkit-animation: item 0.5s linear forwards; /* Safari & Chrome */
        -moz-animation: item 0.5s linear forwards; /* Firefox */
        -ms-animation: item 0.5s linear forwards; /* Internet Explorer */
        -o-animation: item 0.5s linear forwards; /* Opera */
        padding: 0;
        overflow: hidden;
        position: relative;
        display: block;
        width: 200px; //So, the items inside it doesn't overflow when the animation is happening from 0 width to real width for the parent
        height: auto;
        text-decoration: none;
        overflow: hidden;
        border-radius: 10px;
        border: 2px solid transparent;
        padding: 5px;

        @media (max-width: 767px) {
          width: 140px;
        }

        &.cash {
          border: 2px solid #3f3;
          .win_amount {
            color: #3f3;
          }
        }
        &.bonus {
          border: 2px solid #ffbc00;
          .win_amount {
            color: #ffbc00;
          }
        }
        .winner-image {
          position: relative;
          width: 186px;
          @media (max-width: 767px) {
            width: 126px;
          }
          height: auto;
          border-radius: 10px;
          opacity: 0;
          animation: image 1s ease forwards;
          -webkit-animation: image 1s ease forwards; /* Safari & Chrome */
          -moz-animation: image 1s ease forwards; /* Firefox */
          -ms-animation: image 1s ease forwards; /* Internet Explorer */
          -o-animation: image 1s ease forwards; /* Opera */
          &:after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: calc(100% + 27px * 0.8);
            height: calc(100% + 27px * 0.8);
            pointer-events: none;
            // background: url(../images/upper-right.png) no-repeat right top,url(../images/lower-left.png) no-repeat left bottom transparent;
            opacity: 0;
            transform: translate(-50%, -50%) rotate(-180deg) scale(0);
            -webkit-transform: translate(-50%, -50%) rotate(-180deg) scale(0); /* Safari & Chrome */
            -moz-transform: translate(-50%, -50%) rotate(-180deg) scale(0); /* Firefox */
            -ms-transform: translate(-50%, -50%) rotate(-180deg) scale(0); /* Internet Explorer */
            -o-transform: translate(-50%, -50%) rotate(-180deg) scale(0);
            animation: list 0.6s linear forwards;
            -webkit-animation: list 0.6s linear forwards; /* Safari & Chrome */
            -moz-animation: list 0.6s linear forwards; /* Firefox */
            -ms-animation: list 0.6s linear forwards; /* Internet Explorer */
            -o-animation: list 0.6s linear forwards; /* Opera */
            animation-delay: 1s;
          }
          a {
            position: relative;
            display: block;
            width: 100%;
            height: auto;
            text-decoration: none;
            overflow: hidden;
            border-radius: 10px;
            &:after {
              content: "";
              display: block;
              position: absolute;
              top: 0;
              left: -300%;
              width: 300%;
              height: 100%;
              pointer-events: none;
              background: linear-gradient(
                135deg,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0) 33%,
                #fff 50%,
                rgba(255, 255, 255, 0) 66%,
                rgba(255, 255, 255, 0) 100%
              );
              animation: gloss 0.5s linear forwards;
              -webkit-animation: gloss 0.5s linear forwards; /* Safari & Chrome */
              -moz-animation: gloss 0.5s linear forwards; /* Firefox */
              -ms-animation: gloss 0.5s linear forwards; /* Internet Explorer */
              -o-animation: gloss 0.5s linear forwards; /* Opera */
              animation-delay: 0.8s;
            }
            img {
              border-radius: 10px;
              width: 100%;
            }
          }
        }
        .winner-content {
          font-size: 16px;
          line-height: 17px;
          text-align: center;
          .winner_line {
            width: 100%;
            display: block;
            opacity: 0;
            transform: translate(100%, 0);
            -webkit-transform: translate(100%, 0); /* Safari & Chrome */
            -moz-transform: translate(100%, 0); /* Firefox */
            -ms-transform: translate(100%, 0); /* Internet Explorer */
            -o-transform: translate(100%, 0);
            animation: line 0.3s linear forwards;
            -webkit-animation: line 0.3s linear forwards; /* Safari & Chrome */
            -moz-animation: line 0.3s linear forwards; /* Firefox */
            -ms-animation: line 0.3s linear forwards; /* Internet Explorer */
            -o-animation: line 0.3s linear forwards; /* Opera */
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .winner-name {
            color: #fff;
            padding-left: 15px;
          }
          .win-amount {
            padding: 9px 0 5px 13px;
            animation-delay: 0.2s;
            i {
              padding: 2px 10px;
              background: #000;
              color: #ffffff;
              width: auto;
              font-style: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              border-radius: 15px;
            }
          }
          .win-game {
            animation-delay: 0.5s;
            padding-left: 15px;
            a {
              text-decoration: underline;
              color: #fff;
            }
          }
        }
      }
    }
    margin-top: 25px;
    margin-bottom: 20px;
  }
}

@keyframes item {
  0% {
    width: 0px;
  }
  100% {
    @media (min-width: 768px) {
      width: 200px;
    }
    @media (max-width: 767px) {
      width: 150px;
    }
  }
}

@keyframes image {
  0% {
    opacity: 0;
    transform: translate(-100%, 0);
  }
  49% {
    opacity: 1;
    transform: translate(0, 0);
  }
  50% {
    transform: rotate(0deg);
  }
  65% {
    transform: rotate(0deg);
  }
  85% {
    transform: rotate(-0deg);
  }
  100% {
    transform: rotate(0deg) translate(0, 0);
    opacity: 1;
  }
}

@keyframes list {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) scale(1);
  }
}

@keyframes gloss {
  100% {
    left: 0%;
  }
}

@keyframes line {
  0% {
    opacity: 0;
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
