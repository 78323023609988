$theme_color_deep: orange;

.userDropdownMenu,
.settingsDropdown {
  z-index: 4000;
  font-family: $fontFamily;
  // margin-left: 15px;
  .dropdown-menu {
    border-radius: 0px;
    margin-top: 30px;
    background: #1e1b39 !important;
    // border: 1px solid $theme_color_deep;
    width: 200px;
  }

  button {
    z-index: 10000;
    text-transform: capitalize;
    background-color: #37465c4d !important;
    border: none !important;
    color: $white !important;
    padding-right: 20px;
    // height: 50px;
    &:hover,
    &:active,
    &:focus {
      color: #000 !important;
      box-shadow: none !important;
      outline: none !important;
      background-color: $theme_color_deep !important;
    }
  }

  .dropdown-toggle[aria-expanded="true"]:after {
    transform: rotate(180deg);
  }
  /*for animation*/
  .dropdown-toggle:after {
    transition: 0.7s;
    scale: 1.4;
    position: absolute;
    right: 5px;
    top: 18px;
  }

  a {
    padding-left: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $textColor !important;
    // display: block;
    height: 40px;
    line-height: 40px;
    font-size: 13px;
    // width: fit-content;
    opacity: 1;

    img {
      width: 20px;
      margin-right: 10px;
    }
    .theme_bg {
      background: $hoverBg;
      margin-left: 20px;
    }
    // &.page_item {
    //   color: #667c83;
    //   svg {
    //     fill: #667c83;
    //   }
    // }
    .menuText {
      // display: block;
      vertical-align: middle;
    }
    svg {
      fill: $textColor;
      margin-right: 10px;
      // vertical-align: sub;
      path {
        fill: $textColor;
      }
    }
    &.active,
    &:hover {
      background: $hoverBg;
      // background: linear-gradient(180deg, #3ebdf0 0%, #0c8bbe 100%) !important;
      color: $white !important;
      svg {
        fill: $white !important;
        svg {
          path {
            fill: $white;
          }
        }
        g {
          fill: $white !important;
          path {
            fill: $white !important;
          }
        }
      }
    }

    // &:hover {
    //   color: $white;
    //   svg {
    //     fill: $white;
    //   }
    // }
    &.logout_btn {
      .fa-power-off {
        font-size: 20px;
      }
    }
    &.small_circle {
      position: relative;
      .notification_round {
        width: 5px;
        height: 5px;
        background-color: red;
        border-radius: 50%;
        position: absolute;
        top: 18px;
        right: 12px;
      }
    }
  }
  &:hover {
    // background-color: $theme_color_deep !important;
    // a {
    //   color: $black;
    //   svg {
    //     fill: $black;
    //   }
    // }
    color: $theme_color_deep !important;
  }

  .refer_and_earn_menu {
  }
  .button_close {
    color: $white;
    opacity: 0.5;
    margin-right: 20px;
    font-size: 26px;
    float: right;
    &:hover,
    &:focus {
      color: $theme_color_deep;
      opacity: 1;
    }
  }
}

.settingsDropdown {
  .dropdown-toggle {
    svg {
      path {
        fill: white;
      }
    }

    &:hover {
      svg {
        path {
          fill: black;
        }
      }
    }
  }
}
