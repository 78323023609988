.promotions_area {
  // background-color: $mainContentBackground;
  padding: 0.5rem;
  padding-bottom: 50px;
  .promotions_heading {
    position: relative;
    margin-bottom: 1rem;
    // display: none;
    height: 108px;
    align-items: center;
    justify-content: space-between;
    // background-color: var(--db-gray-banner);
    display: flex;
    .promotions_heading_text {
      font-family: $fontFamily;
      font-size: 30px;
      font-weight: 400;
      // text-transform: uppercase;
      color: $white;
    }
    .promotion_image {
      height: 100%;
      img {
        height: 100%;
        transform: translateZ(0);
        -webkit-transform: translateZ(0);
      }
    }
  }
  .promotions_item_area {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    @media (max-width: 1199px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media (max-width: 768px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    justify-content: center;
    gap: 30px;
    .single_promotion {
      position: relative;
      overflow: hidden;
      padding: 20px;
      display: grid;
      grid-template-columns: 2fr 1fr;
      @media (max-width: 768px) {
        grid-template-columns: 3fr 1fr;
        padding: 10px;
      }
      align-items: center;
      // border-radius: 0.375rem;
      // background-color: #2b4154;
      --card-index: 0;
      animation: promotionSlideUp 0.2s ease-out backwards reverse;

      border-radius: 15px;
      // border: 2px solid rgba(87, 119, 163, 0.5);
      // background: linear-gradient(104deg, #161721 2.39%, #212337 98%);

      border: 1px solid rgba(87, 119, 163, 0.5);
      background: linear-gradient(104deg, #1e1b39 2.39%, #212337 98%);
      transition: all 0.5s;

      .promotion_content_area {
        .promotion_title {
          color: #efefef;
          font-family: $fontFamily;
          font-size: 26px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          @media (max-width: 768px) {
            font-size: 16px;
          }
        }
        .promotion_description {
          color: lightblue;
          font-family: $fontFamily;
          font-size: 18px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          @media (max-width: 768px) {
            font-size: 14px;
          }
        }
        .read_more_btn {
          color: #6e7c8f;
          font-family: $fontFamily;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-decoration: none;
        }
      }
      .promotion_image_area {
        display: block;
        // aspect-ratio: 640/350;
        // width: 100%;
        // flex-shrink: 0;
        // cursor: pointer;
        overflow: hidden;
        text-align: right;
        img {
          // height: 115%;
          width: 100%;
          object-fit: cover;
          transition: 300ms;
          &:hover {
            scale: 1.02;
            transition: 300ms;
          }
        }
      }
      .promotion_button_area {
        display: grid;
        gap: 0.5rem;
        padding: 0.75rem 1rem 1rem;
        &.twoItems {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
      }

      &:hover {
        scale: 1.05;
        transition: all 0.5s;
        // border-radius: 30px;
        // border: 2px solid rgba(87, 119, 163, 0.50);
        background: linear-gradient(104deg, #161721 2.39%, #304b6f 98%);
      }
    }
  }

  &.product_area {
    .promotions_item_area {
      .single_promotion {
        padding: 0px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
      }
    }
  }
}

.promotion_details_area {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  .promotion_hero_area {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    // align-items: center;
    // width: 100%;
    flex-direction: column;
    overflow: hidden;
    border-radius: 0.375rem;
    // background-color: #001a35;
    .promotion_hero_image {
      // aspect-ratio: 640/350;
      // width: 50%;
      // --tw-bg-opacity: 1;
      // background-color: rgb(0 0 0 / var(--tw-bg-opacity));
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    .promotion_hero_content {
      display: none;
      align-items: flex-start;
      padding: 2.25rem;
      p {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 600;
        --tw-text-opacity: 1;
        color: rgb(255 255 255 / var(--tw-text-opacity));
      }
    }
  }

  .promotion_details_content_area {
    color: $white;
    padding: 30px 0;
  }
}

.bonusTerms {
  color: #fff;
  padding: 30px;
  @media (max-width: 768px) {
    padding: 15px;
  }

  .page_title {
    color: orange;
    margin-bottom: 30px;
  }
}
