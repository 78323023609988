.wheel_container {
  img {
    max-width: unset !important;
  }
  .wheel_canvas_outer {
    position: relative;
    margin: 0 auto;
    width: 600px;
    @media (max-width: 767px) {
      width: 250px !important;
    }
    .wheel_frame {
      position: relative;
      width: 600px;
      z-index: 50;
      @media (max-width: 767px) {
        width: 250px !important;
      }
    }
    .wheel_canvas {
      position: absolute;
      width: 528px;
      top: 38px;
      left: 35px;
      z-index: 1;
      @media (max-width: 767px) {
        width: 218px !important;
        top: 16px !important;
        left: 16px !important;
        height: auto;
      }
    }
  }
  .spin_button {
    padding: 0 30px;
    margin: 40px auto;
  }
}
