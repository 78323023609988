$purple: #863ab7;
$greyish: #304a71;

$borderColor: #304a71;
$inputBackground: #304a71;
$theme_color_v1: orange;

.register {
  background: $header_bg;
  padding: 20px;
  border-top: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
  position: relative;
  // width: 100%;
  color: $white;
  @media (max-width: 767px) {
    padding: 15px !important;
  }

  .headline {
    color: white;
    line-height: 1;
    font-weight: 700;
    font-size: 28px;
    margin-bottom: 15px;
  }

  .input_item {
    margin: 10px 0;
    position: relative;
    .error_msg {
      position: absolute;
      bottom: -21px;
      font-size: 13px;
    }

    .form-check {
      label {
        font-size: 14px;
      }
    }
  }

  .name_value {
    color: $white;
    background: $inputBackground;
    border: none;
    box-shadow: none;
    &:focus {
      color: $white;
      background: $inputBackground;
      box-shadow: none;
    }
    &::placeholder {
      color: #969494;
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder {
      /* Edge 12 -18 */
      color: #969494;
    }
  }

  .error_msg {
    color: red;
  }

  a.common_link {
    position: relative;
    text-decoration: none;
    color: $white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(
      to right,
      $custom-hover-color,
      $custom-hover-color 50%,
      #fff 50%
    );
    background-size: 200% 100%;
    background-position: -100%;
    transition: all 0.3s ease-in-out;
    &:before {
      display: block;
      content: "";
      width: 0;
      height: 3px;
      bottom: 5px;
      left: 0;
      bottom: -3px;
      z-index: 0;
      position: absolute;
      background: $custom-hover-color;
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      background-position: 0%;
      &:before {
        width: 100%;
      }
    }
    &.active {
      background-position: 0%;
      &:before {
        width: 100%;
      }
    }
  }
}

$font: "Cairo", sans-serif;
$modal_bg_color: #2a2a44;
$bg_color: #06060e;
$main_color: #863ab7;
$main_color_deep: #863ab7;
$input_bg_color: #19192c;
$reg_base_color: #863ab7;
$reg_base_deep_color: #863ab7;
$input_normal_border_color: rgba(0, 0, 0, 0.38);
$input_normal_focused_border_color: #add8e6;

$placeholder_text_color: rgba(0, 0, 0, 0.54);
$input_error_border_color: #e60000;
$input_error_focused_border_color: #e60000;
$input_accept_border_color: #1daa63;
$input_accept_focused_border_color: #1daa63;

.border_radius_20 {
  .modal-content {
    border-radius: 30px !important;
  }
}

// .loginSize {
//   .modal-dialog {
//     max-width: 350px;
//   }
// }
.login_modal {
  overflow: hidden;
  // background: #0c164a;
  border-radius: 10px;
  //   position: relative;
  // border-radius: 4px;
  padding: 0px !important;
  // padding: 30px 30px !important;
  //   width: 400px !important;
  // margin: 0 auto;
  @media (max-width: 575px) {
    // width: 290px !important;
    padding: 10px !important;
  }
  &.reg_section {
    // width: 600px !important;
    @media (max-width: 575px) {
      //   width: 290px !important;
      padding: 10px !important;
    }
  }

  .modal_headline {
    font-family: $font;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 45px;
    color: #dddff8;
    &.sub_headline {
      font-size: 17px;
      line-height: 25px;
    }
    .backStep {
      float: right;
      font-size: 20px;
      cursor: pointer;
    }
  }
  .login_mode {
    background: $bg_color;
    border-radius: 25px;
    width: fit-content;
    margin: 0 auto;

    .mode_btn {
      margin: 5px;
      display: inline-block;
      height: 36px;
      text-decoration: none;
      width: 100px;
      border-radius: 25px;
      line-height: 36px;
      font-family: $font;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      text-align: center;
      text-transform: capitalize;
      color: $white;

      &:hover {
        background: $main_color;
      }
      &.active {
        background: $main_color;
      }
      @media (max-width: 767px) {
        height: 30px;
        line-height: 30px;
        width: 90px;
      }
    }
  }
  .form_area {
    margin: 20px 0 10px;
    @media (max-width: 767px) {
      margin: 10px 0;
    }
    .input_item {
      position: relative;
      margin-bottom: 20px;
      @media (max-width: 575px) {
        margin-bottom: 10px;
      }
      .name {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-transform: capitalize;
        color: $white;
        margin-bottom: 8px;
        color: $textColor;

        .requried_icon {
          color: red;
          padding-left: 5px;
        }
        .forgot_pass_link {
          float: right;
          text-decoration: underline;
          cursor: pointer;
          color: $white;
        }
      }
      .name_valueB {
        border-radius: 10px;
        background: #042d69;
        color: rgba(255, 255, 255, 0.59);
        height: 40px;
        font-family: $font;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        border: none;
        &:focus {
          border: none;
          outline: none;
        }

        @media (max-width: 767px) {
          height: 38px;
          line-height: 38px;
        }
      }
      .show_hide_pass {
        position: absolute;
        right: 10px;
        top: 16px;
        color: rgba(255, 255, 255, 0.69);
      }
      .errorMsg {
        color: red;
        font-size: 12px;
      }

      .three_fields {
        display: flex;
        gap: 20px;
      }
    }
    .login_button {
      margin: 30px 0px;
      text-align: center;
      @media (max-width: 767px) {
        margin: 10px 0px;
      }
      .login_btn {
        width: 100%;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 30px;
        color: #08165d;
        opacity: 1;
        border-radius: 30px;
        background: $theme_color_v1;

        @media (max-width: 767px) {
          height: 34px;
          line-height: 17px;
        }
      }
    }
  }

  .paragraph {
    a {
      color: $reg_base_color;
    }
  }

  .privacy_text {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: $textColor;

    a {
      color: #ff6b2c;
      font-weight: 600;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .routing_text {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    margin-top: 15px;

    color: $textColor;
    a {
      text-decoration: none;
      color: #fff778;
    }
  }
  .bordered_text {
    padding: 15px;
    background: #19192c;
    border-radius: 5px;
    font-size: 13px;
  }

  &.forgot_pass {
    width: 100%;
  }

  .modalBg {
    // height: 100%;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &.login {
      background-image: url("../../../assets/sweeplay/images/lognReg/login-modal.jpg");
    }
    &.register {
      background-image: url("../../../assets/sweeplay/images/lognReg/signup-bg.webp");
      background-position: center -120px;

      @media (min-width: 768px) {
        display: flex !important;
        justify-content: flex-end;
        flex-direction: column;
      }
      .logo {
        justify-content: center;
      }
    }
  }

  &.forgot_pass {
    padding: 30px !important;
  }

  &.message_modal {
    .message_content {
      padding: 30px;
      color: $white;
    }
  }
}

.reg_check_area {
  .check_area {
    width: 100%;
    cursor: pointer;
    .check_check_and_text_area {
      // padding: 0 15px;
      margin-top: 10px;
    }
    .check_box {
      vertical-align: sub;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      display: inline-block;
      border: 2px solid $white;
      border-radius: 5px;

      text-align: center;
      .check_checked_box {
        width: 10px;
        height: 10px;
        background: rgba(106, 73, 183, 1);
        margin: 3px auto 0 auto;
        display: none;
      }
    }
    .check_text {
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      /* or 200% */
      color: $textColor;
      display: inline-block;
      width: calc(100% - 35px);
      vertical-align: top;

      a {
        color: #ff6b2c;
        font-weight: 600;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  input[type="checkbox"] {
    display: none;
    &:checked + label {
      .check_box {
        border: 2px solid rgba(106, 73, 183, 1);
      }
      .check_checked_box {
        display: block;
      }
    }
  }
  .error_text {
    /*display: none;*/
    font-size: 12px;
    line-height: 16px;
    white-space: normal;
    word-break: break-word;
    clear: both;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    color: $input_error_border_color;
    margin: 4px 12px 8px;
  }

  &.error_input {
    .reg_input {
      outline: none;
      border-color: $input_error_border_color;
      -webkit-box-shadow: 0 0 5px $input_error_border_color;
      box-shadow: 0 0 5px $input_error_border_color;
    }
    .input_info {
      display: none;
    }
    .error_text {
      display: block;
    }
  }
}
.loginRegModal {
  @media (min-width: 768px) and (max-width: 991px) {
    .modal-content {
      max-width: 650px;
    }
  }
  .modal-content {
    box-shadow: 0px 0px 15px 0px orange;
  }
}
.login_reg_area {
  padding: 18px 15px 30px 15px;
  @media (max-width: 767px) {
    padding: 5px 0 30px 0;
  }
  .name {
    display: none;
  }

  .social_login_registration {
    text-align: center;
    .social_login_reg_text {
      color: $white;
      font-size: 14px;
      margin-bottom: 5px;
    }
    ul {
      padding: 0px;
      li {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
          0 1px 2px 0 rgba(0, 0, 0, 0.12);
        border-radius: 3px;
        background-color: $purple;
        display: inline-block;
        margin: 0 2px;
        text-align: center;
        a {
          // padding: 0 15px;
          color: $white;
          line-height: 35px;
          width: 80px;
          display: block;
          &:hover {
            color: $black;
          }
        }
      }
    }
  }
}
.signInPromotion {
  // height: 170px;
  width: 100%;
  padding: 15px;
  background: rgba(11, 16, 32, 0.64);
  border-radius: 10px;
  text-align: center;
  position: relative;
  margin-top: auto;
  bottom: 0px;
  &.isMobileDevice {
    position: relative;
    width: 100%;
    background: #281242;
    margin-top: 15px;
    padding: 10px;
    background-image: url("../../../assets/sweeplay/images/lognReg/signup-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 40px;
  }
  .dimondTop {
    position: absolute;
    left: 30px;
    top: 10px;
    @media (max-width: 767px) {
      width: 25px;
      left: 10px;
      top: 5px;
    }
  }
  .dimondBottom {
    position: absolute;
    right: 20px;
    bottom: 10px;
    @media (max-width: 767px) {
      width: 25px;
      right: 20px;
      bottom: 10px;
    }
  }

  .title {
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    color: orange;
    margin-bottom: 10px;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  .count {
    .prize.gcCoins {
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 10px;
      color: #fff;
      img {
        width: 23px;
        margin-top: -6px;
      }
      @media (max-width: 767px) {
        font-size: 14px;
        margin-bottom: 0px;
        img {
          width: 18px;
          margin-top: -4px;
        }
      }
    }
  }
  .foot {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    .tempe {
      box-shadow: 0 0 16px 0 #f0c11a;
      font-size: 22px;
      padding: 2px 8px;
      background-color: #f0c11a;
      color: #000;
      border-radius: 10px;
      font-size: 22px;
      font-weight: 700;
      margin-top: 5px;
      // display: block;
      // width: 100px;
      // height: 25px;.
      @media (max-width: 767px) {
        font-size: 14px;
        padding: 0px 8px;
      }
    }
    .topeka {
      color: #fff;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
    .stripText {
      text-decoration: line-through;
      color: #fff;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
}
.errorMsg {
  font-size: 12px;
  color: red;
}

.promoAccordion {
  &.accordion {
    background: unset;
    // margin-bottom: 10px;
    .accordion-item {
      background: unset;
      border: none;
      .accordion-header {
        .accordion-button {
          // padding-right: 0px;
          &::after {
            // background-color: red;

            float: right;
            width: 10px;
            height: 16px;
            display: block;
            background: url("../../../assets/sweeplay/images/lognReg/angle-down.svg")
              no-repeat;
            // background: url("../../../assets/sweeplay/images/leftsidebar/slots-icon.svg")
            // no-repeat;
            background-position: left;
          }

          .titleLine {
            width: 100%;
            border-top: 1px solid #9aa0b1;
          }
          .titleText {
            width: 100%;
          }

          // &:not(.collapsed)::after {
          //   float: right;
          //   width: 20px;
          //   height: 20px;
          //   display: block;
          //   background: url("../assets/sweeplay/images/leftsidebar/sort-down-solid.svg")
          //     no-repeat;
          //   background-position: left;
          // }
        }
      }
    }
    .accordion-body {
      padding: 0;
      .dropdownMenu {
        border-radius: 10px;
        // border: 1px solid rgba(255, 255, 255, 0);
        // background: rgba(12, 12, 21, 0.5);
        backdrop-filter: blur(10px);
        border-bottom: none;
        // padding: 15px 0;
        a {
          padding: 0 15px;
          text-decoration: none;
          display: block;
          line-height: 50px;
          height: 50px;
          align-items: center;
          text-decoration: none;
          border-bottom: 1px solid rgba(255, 255, 255, 0);
          transition: all 0.5s;
          .text {
            height: 50px;
            line-height: 50px;
            padding-top: 0;
            font-weight: 500;
            color: #fff;
            font-family: $fontFamily;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            // line-height: 24px; /* 171.429% */
            text-decoration: none;
            // padding-top: 5px;
            display: block;
          }
          &:hover {
            background: $sidebarBackground;
            text-decoration: underline;
            color: var(--landing-color);
            transition: all 0.5s;
            .text {
              color: var(--landing-color);
            }
          }
          &:last-child {
            border: none;
          }
        }
      }
    }
    button {
      box-shadow: none;
      border: none;
      position: relative;
      // z-index: 1;
      display: block;
      // background: url("../assets/images/common/promotions-btn-bg.png");
      background: unset;
      // box-shadow: 0px 0px 15px 2px rgba(16, 16, 16, 0.5) inset;
      // -webkit-box-shadow: 0px 0px 15px 2px rgba(16, 16, 16, 0.5) inset;
      // -moz-box-shadow: 0px 0px 15px 2px rgba(16, 16, 16, 0.5) inset;
      background-size: cover;
      height: 50px;
      // line-height: 20px;
      text-align: left;
      color: $textColor;
      border-radius: 12px;
      font-family: $fontFamily;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;
      transition: all 0.5s;
      padding: 0 15px;
      // box-shadow: 0px 0px 0px 3px #f1980c;
      display: flex;
      gap: 10px;
      &:hover {
        color: #cc5fff;
        text-decoration: none;
        background-color: $leftLinkHover !important;
        box-shadow: 0px 0.5px 0px 3px $leftLinkHover;
        .icon {
          width: 34px;
          svg {
            fill: #cc5fff;
            path {
              fill: #cc5fff;
            }
          }
        }
      }
      // &::before {
      //   content: "";
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   width: 100%;
      //   height: 100%;
      //   // border-radius: 50%;
      //   background: red;
      //   transition: 0.5s;
      //   transform: scale(1);
      //   z-index: -1;
      // }
      .icon {
        width: 34px;
        svg {
          width: 20px;
        }
      }
      .text {
      }
    }

    .casinoGamesBtn {
      button {
        display: flex;
        gap: 10px;
        .icon {
          svg {
            width: 20px;
            fill: #fff;
          }
        }
        .text {
        }
      }
    }
    .liveCasinoGamesBtn {
      button {
        display: flex;
        gap: 10px;
        .icon {
          svg {
            // width: 24px;
            fill: #fff;
          }
        }
        .text {
        }
      }
    }
    .responsibleGamingBtn {
      button {
        display: flex;
        gap: 10px;
        .icon {
          svg {
            width: 26px;
            path {
            }
            // fill: #fff;
          }
        }
        .text {
        }
      }
    }
  }
}

.socialLoginArea {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  // grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  align-items: center;
  .facebookLoginBtn {
    background: #002748;
    border: 1px solid #007ad0;
    // padding: 10px 16px;
    border-radius: 10px;
    text-align: center;
    // width: 100%;
    button {
      background: unset;
      border: none;
      text-align: center;
      margin: 0 auto;
      width: 115px;
      height: 48px;
      .fab {
        font-size: 16px;
        color: #007ad0;
      }
    }
  }
  .googleLoginBtn {
    background: #002748;
    border: 1px solid #007ad0;
    // padding: 13px 16px;
    border-radius: 10px;
    // width: 100%;
    .googleLoginButton {
      width: 115px;
      height: 48px;
      margin: 0 auto;
      [role="button"] {
        width: 115px !important;
        height: 48px !important;
        background-color: unset !important;
        border: none;
        div {
          display: block !important;
          text-align: center !important;
        }
      }
      svg {
        margin: 12px auto;
        width: 15px;

        margin-left: 46px;
        // margin-left: -5px;
        // @media (max-width: 767px) {
        //   margin-left: -4px;
        // }
      }
    }
  }
}

.or_text_section {
  padding-top: 15px;
  color: #fff;
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  .dash {
    border-top: 1px solid #9aa0b1;
  }
  .text {
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
  }
}

.forgot_pass_link {
  font-size: 12px;
  float: right;
  color: orange;
}
