$leftMenuBackground: #000;
$sidebarBackground: #11112b;
$leftLinkHover: #1f1842;

//Temp need to use common var
$fontFamily: "Jost";
$buttonColorOne: purple;
$light_block_bg: darkgray;
$text_color_v1: white;

.navSidebar {
  position: relative;
  z-index: 105;
  overflow: hidden;

  &.rightSidebar {
    right: 0px;
  }

  &.inFull {
    padding: 10px;
    width: 65px;

    &.opend {
      width: 320px;
      @media (max-width: 767px) {
        width: 100vw;
      }
    }
  }

  @media (max-width: 991px) {
    position: absolute;
    top: 80px;
  }
  @media (max-width: 767px) {
    position: fixed;
    top: 50px;
  }
  // top: 80px;
  .navSidebarContent {
    background: $sidebarBackground;
    border-radius: 25px;
    transition: all 0.2s ease;
    width: var(--nav-sidebar-width);
    height: calc(100vh - 115px);
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    justify-content: flex-end;
    display: flex;

    @media (max-width: 767px) {
      display: block;
      overflow-x: hidden;
    }

    .sidebar_container {
      position: relative;
    }

    .leftSideHeading {
      // height: 64px;
      background-color: $sidebarBackground;
      text-align: center;
      padding: 8px;
      border-bottom: 1px solid var(--landing-color);
      // display: grid;
      display: flex;
      justify-content: space-between;
      grid-template-columns: 50px 1fr;
      border-radius: 25px 25px 0 0;
      position: relative;

      .actionBar {
        width: 50px;
        height: 57px;
        line-height: 50px;
        text-align: center;
        margin: 0 auto;
        cursor: pointer;
        svg {
          width: 32px;
          path {
            fill: #fff;
          }
        }
      }
      .sideMenuBar {
        float: right;
      }
    }
    .leftSideContent {
      border-radius: 0 0 25px 25px;
      background: $sidebarBackground;
      position: relative;
      min-width: 66px;
      overflow-x: hidden;
      z-index: 1;

      //Left Side Animation
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 0.3s;

      // display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;

      @media (max-width: 768px) {
        left: 0;
        display: block;
        // padding: 0 5px;
        // width: 275px;
      }

      @media (max-width: 1340px) {
        display: block;
      }

      @media (min-width: 992px) and (max-width: 1199px) {
        // width: 200px;
      }

      .leftside_logo {
        text-align: center;
        padding: 15px 0;
      }

      .topSideBar {
        background-color: $sidebarBackground;
        padding: 15px 0;
        @media (max-width: 768px) {
          background: unset;
        }
      }
      .bottomSideBar {
        // background: $sidebarBackground;
        // height: calc(100%);
        // overflow-y: auto;
        position: relative;
        padding: 15px 8px;
        // height: calc(100% - 100px);
        margin-bottom: auto;
        &.bottomPart {
          margin-top: auto;

          // background-color: #1e1b39;
          margin-left: 5px;
          margin-right: 5px;
          margin-bottom: 5px;
          border-radius: 20px;
          width: 288px;
          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }

      .promotion_button_group {
        // padding: 0 8px;

        .bonusBtn,
        .promotionsBtn {
          color: $textColor;
          // margin-bottom: 10px;
          .icon {
            text-align: left;
            img {
              width: 22px;
            }
            svg {
              width: 20px;
              margin-right: 0;
              fill: $textColor;
              path {
                fill: $textColor;
              }
            }
          }
          transition: all 0.5s;
          // box-shadow: 0px 0px 0px 3px #f1980c;
          &:hover {
            color: #cc5fff;
            // text-decoration: none;
            // background-color: $leftLinkHover;
            background-color: $leftLinkHover;
            box-shadow: 0px 0.5px 0px 3px $leftLinkHover;
            .icon {
              svg {
                fill: #cc5fff;
                path {
                  fill: #cc5fff;
                }
              }
            }
          }
        }

        &.bottomBlock {
          @media (min-height: 900px) {
            position: absolute;
            bottom: 10px;
          }
        }
      }
      .menus {
        padding: 5px 0px;
        // border-bottom: 1px solid $text_color_v1_dark;
        margin: 0;
        background-color: #032040;
        overflow: hidden;
        li {
          transition: 0.3s;
          padding: 0 15px;
          a {
            text-decoration: none;
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 50px;
            color: $white;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            // display: flex;

            .icon {
              margin-right: 14px;
            }
            .arrow {
              display: none;
            }
            &.active {
              color: $buttonColorOne;
              svg {
                path {
                  fill: $buttonColorOne;
                }
              }
            }
          }
          &:hover {
            transform: translate(0, -3px);
            background-color: #06264c;
          }
        }
      }

      .left_side_menu_group {
        // padding: 0 8px;
        position: relative;
        z-index: 1;

        .dropdown-toggle {
          width: 100%;
          background-color: unset;
          border: none;
          text-align: left;

          color: #3e3e55;
          font-family: $fontFamily;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 150% */

          &::after {
            float: right;
            margin-top: 10px;
            scale: 1.8;
          }
        }
        .btn-success:not(:disabled):not(.disabled).active:focus,
        .btn-success:not(:disabled):not(.disabled):active:focus,
        .show > .btn-success.dropdown-toggle:focus {
          box-shadow: none !important;
          outline: 0 !important;
        }
        .dropdown-menu {
          width: 100%;

          border-radius: 10px;
          border: 1px solid rgba(255, 255, 255, 0);
          background: rgba(12, 12, 21, 0.5);
          backdrop-filter: blur(10px);
          border-bottom: none;

          a {
            padding: 0 15px;
            display: grid;
            grid-template-columns: 1fr 5fr;
            line-height: 50px;
            height: 50px;
            align-items: center;
            text-decoration: none;
            border-bottom: 1px solid rgba(255, 255, 255, 0);
            .text {
              color: #fff;
              font-family: $fontFamily;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px; /* 171.429% */
              text-decoration: none;
              padding-top: 5px;
              display: block;
            }
          }
        }

        &.GDbtnStyle {
          .dropdown,
          .accordion {
            background: unset;
            // margin-bottom: 10px;
            .accordion-item {
              background: unset;
              border: none;
              .accordion-header {
                .accordion-button {
                  // padding-right: 0px;
                  &::after {
                    // background-color: red;

                    float: right;
                    width: 10px;
                    height: 16px;
                    display: block;
                    // background: url("assets/sweeplay/images/leftsidebar/sort-down-solid.svg")
                    //   no-repeat;
                    // background: url("../../../assets/sweeplay/images/leftsidebar/slots-icon.svg")
                    // no-repeat;
                    background-position: left;
                  }

                  // &:not(.collapsed)::after {
                  //   float: right;
                  //   width: 20px;
                  //   height: 20px;
                  //   display: block;
                  //   background: url("../assets/sweeplay/images/leftsidebar/sort-down-solid.svg")
                  //     no-repeat;
                  //   background-position: left;
                  // }
                }
              }
            }
            .accordion-body {
              padding: 0;
              .dropdownMenu {
                border-radius: 10px;
                // border: 1px solid rgba(255, 255, 255, 0);
                // background: rgba(12, 12, 21, 0.5);
                backdrop-filter: blur(10px);
                border-bottom: none;
                // padding: 15px 0;
                a {
                  padding: 0 15px;
                  text-decoration: none;
                  display: block;
                  line-height: 50px;
                  height: 50px;
                  align-items: center;
                  text-decoration: none;
                  border-bottom: 1px solid rgba(255, 255, 255, 0);
                  transition: all 0.5s;
                  .text {
                    height: 50px;
                    line-height: 50px;
                    padding-top: 0;
                    font-weight: 500;
                    color: #fff;
                    font-family: $fontFamily;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    // line-height: 24px; /* 171.429% */
                    text-decoration: none;
                    // padding-top: 5px;
                    display: block;
                  }
                  &:hover {
                    background: $sidebarBackground;
                    text-decoration: underline;
                    color: var(--landing-color);
                    transition: all 0.5s;
                    .text {
                      color: var(--landing-color);
                    }
                  }
                  &:last-child {
                    border: none;
                  }
                }
              }
            }
            button {
              box-shadow: none;
              border: none;
              position: relative;
              // z-index: 1;
              display: block;
              // background: url("../assets/images/common/promotions-btn-bg.png");
              background: unset;
              // box-shadow: 0px 0px 15px 2px rgba(16, 16, 16, 0.5) inset;
              // -webkit-box-shadow: 0px 0px 15px 2px rgba(16, 16, 16, 0.5) inset;
              // -moz-box-shadow: 0px 0px 15px 2px rgba(16, 16, 16, 0.5) inset;
              background-size: cover;
              height: 50px;
              // line-height: 20px;
              text-align: left;
              color: $textColor;
              border-radius: 12px;
              font-family: $fontFamily;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              text-transform: uppercase;
              transition: all 0.5s;
              padding: 0 15px;
              // box-shadow: 0px 0px 0px 3px #f1980c;
              display: flex;
              gap: 10px;
              &:hover {
                color: #cc5fff;
                text-decoration: none;
                background-color: $leftLinkHover !important;
                box-shadow: 0px 0.5px 0px 3px $leftLinkHover;
                .icon {
                  width: 34px;
                  svg {
                    fill: #cc5fff;
                    path {
                      fill: #cc5fff;
                    }
                  }
                }
              }
              // &::before {
              //   content: "";
              //   position: absolute;
              //   top: 0;
              //   left: 0;
              //   width: 100%;
              //   height: 100%;
              //   // border-radius: 50%;
              //   background: red;
              //   transition: 0.5s;
              //   transform: scale(1);
              //   z-index: -1;
              // }
              .icon {
                width: 34px;
                svg {
                  width: 20px;
                }
              }
              .text {
              }
            }

            .casinoGamesBtn {
              button {
                display: flex;
                gap: 10px;
                .icon {
                  svg {
                    width: 20px;
                    fill: #fff;
                  }
                }
                .text {
                }
              }
            }
            .liveCasinoGamesBtn {
              button {
                display: flex;
                gap: 10px;
                .icon {
                  svg {
                    // width: 24px;
                    fill: #fff;
                  }
                }
                .text {
                }
              }
            }
            .responsibleGamingBtn {
              button {
                display: flex;
                gap: 10px;
                .icon {
                  svg {
                    width: 26px;
                    path {
                    }
                    // fill: #fff;
                  }
                }
                .text {
                }
              }
            }
          }
        }
      }
      .theme_mode {
        padding-left: 0;
        padding-top: 0;
        margin-top: 25px;
        display: flex;
        justify-content: space-between;
        @media (min-width: 992px) and (max-width: 1199px) {
          display: block;
        }
        li {
          background: $light_block_bg;
          border: 1px solid #dddcdc;
          border-radius: 18px;
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 28px;
          color: $text_color_v1;
          padding: 0px 10px 0px 4px;
          vertical-align: baseline;
          opacity: 0.6;
          transition: 0.3s;
          cursor: pointer;
          @media (min-width: 992px) and (max-width: 1199px) {
            width: fit-content;
            margin-bottom: 10px;
          }

          &.dark {
            padding: 0px 4px 0px 10px !important;
            .icon {
              margin-right: 0;
              margin-left: 10px;
            }
          }

          .icon {
            margin-right: 10px;
            img {
              margin-top: -2px;
            }
          }

          &.active {
            background: $light_block_bg;
            opacity: 1;
          }
          &:hover {
            transform: translate(0, -3px);
          }
        }
      }

      .casino_type {
        display: flex;
        gap: 10px;
        a {
          /* Rectangle 2793 */

          // position: absolute;
          width: 140px;
          line-height: 36px;
          text-align: center;

          color: #fff;

          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          text-decoration: none;
          .text {
            font-family: "Jost";
            font-size: 20px;
            font-weight: normal;
            line-height: 20px;
            font-style: normal;
            text-align: left;
            color: #ffffff;
            text-decoration: none;
          }
          .icon {
            img {
              width: 26px;
            }
          }
          &:hover,
          &.active {
            background: #06274b;
            border-color: #000000;
            border-width: 1px;
            border-style: dashed;
            border-radius: 5px;
          }
        }
      }
      .liveSupportBtn {
        // width: 100%;
        display: block;
        margin: 0 0px;
        line-height: 50px;
        height: auto;
      }
    }
  }
  &.opend {
    .navSidebarContent {
      width: 100%;
      @media (max-width: 767px) {
        // width: 100vw;
      }
    }
    .leftSideContent {
      width: 100%;
      @media (max-width: 767px) {
        // width: 100vw;
      }
    }

    .leftSideHeading {
      .foldInIcon {
        rotate: 180deg;
        transition: all 0.5;
        -webkit-animation: rotate-180-cw 0.4s
          cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: rotate-180-cw 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      }
    }
  }

  &.inFull {
    .shortName {
      display: none;
    }
    .fullName {
      display: block;
    }
  }
  &.inShort {
    .fullName {
      display: none;
    }
    .shortName {
      display: block;
      text-transform: uppercase;
      text-align: center;
    }
    .languageSwitcherAccordion .languageItemLists li input {
      display: none;
    }
    .languageSwitcherAccordion .languageItemLists li {
      grid-template-columns: 100%;
      padding: 10px;
    }

    .commonBtn.bonusBtn {
      width: 50px;
      display: block;
      text-align: center;
      padding: 0px;
      background: #000000;
      svg {
        margin-right: 0px;
      }
      .text {
        display: none;
      }
    }
    .leftSideHeading {
      display: block !important;
      .shotSettings {
        display: none;
      }
      .foldOutIcon {
        transition: all 0.5;
        -webkit-animation: rotate-180-cw 0.4s
          cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: rotate-180-cw 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      }
    }
    .accordionBtnStyle {
      button {
        display: block !important;
        padding: 0px !important;
        text-align: center !important;
        .text {
          display: none;
        }
        .icon {
          text-align: center;
          margin: 0 auto;
        }
        &::after {
          display: none !important;
        }
      }
    }
    .buy-crypto-block__methods {
      display: none;
    }
  }

  &.fullHide {
    .navSidebarContent {
      width: 0;
    }
    &.leftSidebar {
      .navSidebarContent {
        width: 65px;
        @media (max-width: 991px) {
          width: 0px;
        }
      }
      &.mobleDevice {
        width: 0px;
      }
    }
  }
}

.cTAGroup {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  background-color: rgba(55, 70, 92, 0.3);
  padding: 7px 5px;
  border-radius: 15px;
  height: 50px;
  width: 100%;
  .commonBtn {
    // text-wrap: nowrap;
    white-space: nowrap;
    // width: 50%;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 0 10px;
    // border-radius: 5px !important;

    .icon {
      img {
        width: 20px;
        margin-top: -2px;
      }
    }
  }
}

.leftSidePromotionBlock {
  position: relative;
  .promotionContent {
    font-size: 15px;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 25px 0 0 25px;
    color: #fff;
    font-weight: 500;
    span {
      font-size: 24px;
      // text-transform: uppercase;
      font-weight: bold;
    }
  }
  img {
    width: 97%;
    @media (max-width: 767px) {
      // width: 78%;
    }
  }
}
