$greenColor: green;

.vip_page_area {
  padding: 0px 0 50px 0;
  background: url("../../assets/sweeplay/images/affiliates/vip-bg.png")
    no-repeat top center;
  background-size: contain;
  position: relative;

  .main_heading {
    color: #efefef;
    font-family: $fontFamily;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    // margin-bottom: 50px;
    position: absolute;
    top: 50px;
    left: 0px;
    z-index: 10;
  }
  .vip_block_banner {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 30px;
    @media (min-width: 768px) and (max-width: 991px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 768px) {
      display: block;
      margin-top: 100px;
    }
    .left_item {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      border-radius: 15px;
      border: 1px solid rgba(87, 119, 163, 0.5);
      background: linear-gradient(104deg, #161721 2.39%, #212337 98%);
      @media (max-width: 768px) {
        display: block;
        margin-bottom: 30px;
      }
      .text_part {
        padding: 15px;
        @media (max-width: 768px) {
          margin-top: -80px;
          padding: 0px;
          text-align: center;
        }
        h4 {
          color: #efefef;
          font-family: $fontFamily;
          font-size: 22px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        p {
          color: #efefef;
          font-family: $fontFamily;
          font-size: 16px;
          font-style: normal;
          // font-weight: 500;
          line-height: normal;
        }
      }
      .img_part {
        text-align: right;
        img {
          height: 200px;
        }
      }
    }
    .right_item {
      display: grid;
      grid-template-columns: 3fr 1fr;
      align-items: center;
      border-radius: 15px;
      border: 1px solid rgba(87, 119, 163, 0.5);
      background: linear-gradient(106deg, #202338 0%, #1f2997 98.31%);
      @media (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }
      .text_part {
        padding: 15px;
        h4 {
          color: #efefef;
          font-family: $fontFamily;
          font-size: 22px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        a {
        }
      }
      .img_part {
        text-align: right;
        img {
          height: 200px;
          @media (max-width: 768px) {
            height: 170px;
          }
        }
      }
    }
  }
  .vip_common_block_heading {
    color: #efefef;
    font-family: $fontFamily;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    margin: 50px 0;
  }

  .vip_explore_area {
    ul {
      padding: 0px;
      margin: 0px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 40px;
      @media (max-width: 768px) {
        display: block;
      }
      li {
        text-align: center;
        border-radius: 15px;
        border: 1px solid rgba(87, 119, 163, 0.5);
        opacity: 0.5;
        background: linear-gradient(104deg, #161721 2.39%, #212337 98%);
        transition: all 0.5s;
        @media (max-width: 768px) {
          margin-bottom: 50px;
        }
        .vipGiftIcon {
          width: 60px;
          margin-top: -35px;
        }
        .vipDiamondIcon {
          width: 60px;
          margin-top: -35px;
        }
        .vipLoveIcon {
          width: 60px;
          margin-top: -35px;
        }
        .explore_heading {
          color: #fff;
          font-family: $fontFamily;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .explore_sub_heading {
          color: #efefef;
          text-align: center;
          font-family: $fontFamily;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 15px;
        }
        &:hover,
        &.active {
          opacity: 1;
          scale: 1.1;
        }
      }
    }
  }
  .vip_levels_area {
  }
  .vip_levels_area {
    ul {
      padding: 0px;
      margin: 0px;
      // display: grid;
      // grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      // grid-gap: 15px;
      li {
        position: relative;
        text-align: center;
        border-radius: 18px;
        width: 200px !important;
        // border: 1px solid rgba(87, 119, 163, 0.5);
        // opacity: 0.5;
        background: linear-gradient(104deg, #161721 2.39%, #212337 98%);
        // transition: all 0.5s;
        // min-height: 250px;
        // padding: 10px;
        @media (max-width: 768px) {
          width: 200px !important;
        }
        .vip_level_content {
          position: absolute;
          top: 10px;
          left: 50%;
          transform: translateX(-50%);
        }

        img {
          height: 250px;
          @media (max-width: 768px) {
            height: 248px !important;
          }
        }
        h3 {
          color: #efefef;
          text-align: center;
          font-family: $fontFamily;
          font-size: 26px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        a {
          color: #6e7c8f;
          text-align: center;
          font-family: $fontFamily;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-decoration: none;
          transition-duration: 500ms;
          transition-property: all;
          transition: 300ms;
          &:hover {
            color: #f1980c;
            transform: translateY(-4px) !important;
            transition-duration: 500ms;
            transition-property: all;
            //  bottom: 32px;
          }
        }
      }
    }
    &.affiliate_commission_plan {
      li {
        width: auto !important;
        padding: 0 15px;
        background: unset !important;
        .vip_level_content {
          top: 50px;
          @media (max-width: 768px) {
          }
        }
        .outer_Layer {
          background: linear-gradient(104deg, #1e1b39 2.39%, #212337 98%);
          border-radius: 15px;
        }
        img {
          height: auto !important;
          width: 100%;
        }
      }
    }
  }

  .loyalty_coin_store {
    text-align: center;
    width: 500px;
    margin: 80px auto;
    border-radius: 15px;
    border: 1px solid rgba(87, 119, 163, 0.5);
    background: linear-gradient(104deg, #1e1b39 2.39%, #212337 98%);
    @media (max-width: 768px) {
      width: 100%;
    }
    img {
      width: 70px;
      margin-top: -35px;
    }
    h3 {
      color: #efefef;
      text-align: center;
      font-family: $fontFamily;
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 0px;
    }
    h6 {
      color: #efefef;
      text-align: center;
      font-family: $fontFamily;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 15px;
    }
  }

  .accordionBlock {
    .accordion {
      .accordion-item {
        border: none;
        border-radius: 15px;
        background: linear-gradient(104deg, #1e1b39 2.39%, #212337 98%);
        margin-bottom: 10px;
        .accordion-header {
          .accordion-button {
            background-color: unset;

            box-shadow: none;
            border-bottom: none;
            padding: 20px 15px;

            color: #fff;
            text-align: center;
            font-family: $fontFamily;
            font-size: 20px;
            font-style: normal;
            // font-weight: 500;
            line-height: normal;

            display: grid;
            grid-template-columns: 1fr 30px;

            &:not(.collapsed)::after {
              background-image: url("../../assets/sweeplay/images/affiliates/arrowDown.svg");
            }
            &::after {
              background-image: url("../../assets/sweeplay/images/affiliates/arrowDown.svg");
            }
          }
        }
      }
      .accordion-body {
        background-color: unset;
        color: $white;
        color: #efefef;
        text-align: center;
        font-family: $fontFamily;
        font-size: 16px;
        font-style: normal;
        // font-weight: 600;
        line-height: normal;

        a {
          color: #f1980c;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .vip_footer_banner {
    border-radius: 15px;
    border: 1px solid rgba(87, 119, 163, 0.5);
    background: linear-gradient(104deg, #212337 2.39%, #1d2aaf 98%);
    display: grid;
    grid-template-columns: 70% 30%;
    align-items: center;
    margin-top: 50px;
    @media (max-width: 768px) {
      text-align: center;
      display: block;
    }
    .left_side {
      padding: 15px;
      h3 {
        color: #efefef;
        font-family: $fontFamily;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      p {
        color: #efefef;
        font-family: $fontFamily;
        font-size: 16px;
        font-style: normal;
        // font-weight: 500;
        line-height: normal;
      }
    }
    .right_side {
      text-align: right;
      img {
        width: 100%;
      }
      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  //Previous design
  .vip_banner {
    padding: 132px 0 59px;
    @media (max-width: 767px) {
      padding-top: 60px;
    }
    .banner_heading {
      color: #fff;
      font-size: 43px;
      font-weight: 900;
      line-height: 1.2;
      margin: 0 0 37px;
      max-width: 660px;
      position: relative;
      text-transform: uppercase;
      span {
        color: #fa305c;
      }
      @media (max-width: 767px) {
        font-size: 35px;
        line-height: 44px;
        margin: 0 auto 30px;
        max-width: none;
        text-align: center;
      }
    }
    .benefits_items {
      padding-left: 0;
      li {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-bottom: 10px;
        @media (max-width: 767px) {
          display: block;
          text-align: center;
          margin-bottom: 30px;
        }
        .icon {
          img {
            width: 82px;
            @media (max-width: 768px) {
              margin-left: -10px;
            }
          }
        }
        .text {
          .topText {
            color: #fff;
            font-size: 25px;
            line-height: 1.2;
            margin: 0 0 3px;
            text-transform: uppercase;
            display: block;
          }
          .bottomText {
            color: #9db8e1;
            font-size: 15px;
            line-height: 23px;
            margin: 0;
            display: block;
          }
        }
        @media (max-width: 768px) {
        }
      }
    }
    .bannerBg {
      width: 100%;
      @media (max-width: 768px) {
        position: relative;
        right: -10px;
      }
    }
  }

  .vip_common_heading {
    text-align: center;
    .common_heading {
      color: #fff;
      font-size: 40px;
      line-height: 1.5;
      margin: 0 auto 21px;
      text-align: center;
      text-transform: uppercase;
      @media (max-width: 767px) {
        font-size: 35px;
        line-height: 45px;
        margin: 0 auto 19px;
      }
    }
    .heading_content {
      color: #9db8e1;
      font-size: 18px;
      line-height: 1.3;
      margin: 0 auto;
      max-width: 810px;
      text-align: center;
    }
  }

  .rank_area {
    .rank_items {
      padding: 50px 0;
      ul {
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: center;
        padding-left: 0px;
        margin: 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
        @media (max-width: 767px) {
          display: block;
        }
        li {
          padding: 15px;
          position: relative;

          background: linear-gradient(318.42deg, #1e75dc 2.97%, #072445 95.55%);
          border-radius: 20px;
          font-weight: 600;
          @media (max-width: 767px) {
            margin-bottom: 30px;
          }
          &::after {
            background: #0d1c31
              url("../../assets/sweeplay/images/affiliates/right-arrow.svg")
              no-repeat 20px;
            border-radius: 50%;
            content: "";
            display: block;
            height: 50px;
            margin: -5px 0 0;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(70%, -50%);
            width: 50px;
            z-index: 1;
            @media (max-width: 767px) {
              top: unset;
              bottom: -0px;
              right: calc(50%);
              rotate: 90deg;
            }
          }

          .rank_top {
            display: flex;
            justify-content: space-between;
            img {
              width: 145px;
            }
            svg {
              //   width: 125px;
            }
          }
          .rank_item_heading {
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            color: #fff;
            display: -webkit-box;
            font-size: 25px;
            line-height: 1.32;
            margin: 0 0 9px;
            overflow: hidden;
            position: relative;
            text-transform: uppercase;
            z-index: 1;
          }
          .rank_item_details {
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            color: #9db8e1;
            display: -webkit-box;
            font-size: 15px;
            line-height: 23px;
            margin: 0;
            overflow: hidden;
            position: relative;
            z-index: 1;
          }

          &:last-child {
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
  .levels_area {
    .level_items {
      padding-top: 80px;
      //   display: grid;
      //   grid-template-columns: 1fr 1fr 1fr;
      gap: 15px;
      .level_item {
        background: url("../../assets/sweeplay/images/affiliates/rank-bg.webp");
        background-size: 95%;
        background-position: center top;
        background-repeat: no-repeat;
        // margin: 0 15px;
        .level_top {
          height: 80px;
          margin: auto;
          position: relative;
          width: 80px;
          img {
            height: 80px;
            width: 80px;
            // margin-top: -25px;
          }
        }
        .level_title {
          text-align: center;
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          text-align: center;
          color: $white;
        }
        .level_details {
          text-align: center;
          .item_detials {
            margin-bottom: 10px;
            .item_level {
              color: #93acd3;
              font-size: 10px;
              font-weight: 500;
              line-height: 14px;
              margin: 0;
              text-align: center;
            }
            .item_text {
              font-size: 18px;
              font-weight: 600;
              line-height: 24px;
              text-align: center;
              color: $white;
              .yellowText {
                color: #ffb636;
              }
              .greenText {
                color: $greenColor;
              }
            }
          }
        }
        .possibilities_area {
          text-align: center;
          padding: 15px;

          ul {
            padding-left: 0;
            margin-left: 0px;
            li {
              text-align: center;
              display: flex;
              justify-content: center;
              gap: 10px;
              color: #55657e;
              font-size: 12px;
              font-weight: 500;
              line-height: 24px;
              svg {
                width: 12px;
                fill: #55657e;
              }

              &.active {
                color: #fff;
                svg {
                  fill: $greenColor;
                }
              }
            }
          }
        }
      }
    }
  }

  .red_text {
    color: #fa305c;
  }

  .vip_club_area {
    // background-image: url("../../assets/images/v2/vip/about-vip-bg.webp");
    // background-position: 100%;
    // background-repeat: no-repeat;
    // background-size: 100% 100%;
    border-radius: 20px;
    margin-bottom: 50px;
    margin-top: 70px;
    min-height: 300px;
    // padding: 75px;
    position: relative;
    width: 100%;
    @media (max-width: 767px) {
      background-size: unset;
      background-position: unset;
      padding: 25px 0px;
    }
    .vip_club_heading {
      font-size: 40px;
      line-height: 60px;
      margin: 0 0 20px;
      text-transform: uppercase;
      color: #fff;
    }
    .vip_club_sub_details {
      color: #9db8e1;
      font-size: 18px;
      line-height: 150%;
      max-width: 355px;
    }
    .vip_club_being {
      font-size: 21px;
      font-style: normal;
      max-width: 441px;
      color: #fff;
      width: 100%;
    }
    ul.vip_club_items {
      padding: 0px;
      margin-top: 15px;

      li {
        display: flex;
        gap: 15px;
        .icon {
          svg {
            width: 12px;
            fill: #fff;
          }
        }
        .text {
          align-items: center;
          margin-bottom: 14px;
          color: #fff;
        }
      }
    }
    .show_high_text {
      color: #fcf076;
      font-size: 17px;
      font-weight: 400;
      // max-width: 400px;
    }
    .more_details_text {
      color: #fff;
      margin-bottom: 5px;
      margin-top: 30px;
    }
    .vipBanner {
      width: 100%;
      @media (max-width: 768px) {
        margin-top: 30px;
        width: 150%;
        position: relative;
        left: -22%;
      }
    }
  }

  .about_genie_vip_club {
    margin: 100px 0;
    .about_genie_title {
      font-size: 24px;
      color: #fff;
      margin-bottom: 30px;
    }
    .accordion {
      .accordion-item {
        border: none;
        background-color: unset;
        .accordion-header {
          .accordion-button {
            background-color: unset;
            color: $white;
            box-shadow: none;
            border-bottom: 1px solid #202a39;
            padding: 20px 0;
          }
        }
      }
      .accordion-body {
        background-color: unset;
        color: $white;
      }
    }
  }

  &.affiliate_page {
    padding-top: 0px;

    .vip_common_block_heading {
      margin: 50px 0 80px 0;
    }
    .affiliate_commission_plan {
      .vip_common_block_heading {
        margin: 55px 0 50px 0;
      }
    }

    .affiliateTerms {
      margin: 50px auto;
    }
  }
}
.centerBlock {
  text-align: center;
}

.benefits-container {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  background-color: #121212; /* Adjust this color based on your design */
  .benefit {
    text-align: center;
    color: #fff; /* White text color */
  }

  .benefit-icon {
    width: 80px; /* Adjust the size based on your design */
    height: auto;
    margin-bottom: 10px;
  }

  .benefit-title {
    font-size: 1.2em;
    margin: 10px 0;
  }

  .benefit-description {
    font-size: 0.9em;
    color: #cccccc; /* Light grey for description */
  }
}

.howItWorksAare {
  text-align: center;
  margin: 30px 0;
  .heading {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
  }
  .details {
    color: #fff;
  }
}

.vipLavelTop {
  text-align: center;
  .rewards_common_block_heading {
    text-align: center !important;
  }
  .tabMenu {
    display: flex;
    margin-bottom: 30px;
    justify-content: center;
    li {
      color: #fff;
      cursor: pointer;
      border-bottom: #646262 1px solid;
      position: relative;
      .selectedMenuBar {
        height: 5px;
        width: 100%;
        position: absolute;
        bottom: -3px;
        left: 0;
        background: linear-gradient(90deg, #4cd1fe 1.33%, #cd8eff 100%);
        border-radius: 3px;
        display: none;
        transition: all 0.5s;
      }
      &:hover,
      &.active {
        .selectedMenuBar {
          display: block;
        }
      }
    }
  }
}
