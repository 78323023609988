.homeElementBlock {
  @media (max-width: 991px) {
    background: linear-gradient(135deg, #6b1c9b, #ff8a00);
  }
  color: white;
  padding: 20px;
  border-radius: 10px;
  //   max-width: 800px;
  margin: 0 auto;
  font-family: "Myriad Pro";
  text-align: left;

  position: relative;
  margin: 40px 0;

  .blockBg {
    width: 100%;
    &.tab {
      display: none;
    }
    @media (max-width: 991px) {
      display: none;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      // width: auto;
      height: 100%;
      &.desktop {
        display: none;
      }
      &.tab {
        display: none;
      }
    }
  }
  .homeBlockContent {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 100px;
    @media (max-width: 991px) {
      position: relative;
      padding: 0px;
      transform: unset;
    }
  }
  &.stepRightUp {
    .homeBlockContent {
      width: 70%;
      @media (max-width: 991px) {
        width: 100%;
      }
    }
  }

  &.bringOn {
    .homeBlockContent {
      width: 70%;
      right: 0px;
      @media (max-width: 991px) {
        width: 100%;
      }
    }
  }
  .sweepplay-title {
    font-size: 2em;
    margin-bottom: 30px;
    text-align: left;
    font-weight: 600;
    @media (max-width: 767px) {
      margin-bottom: 15px;
    }
  }

  .sweepplay-paragraph {
    margin: 10px 0;
    line-height: 1.6;
    font-size: 14px;
    font-weight: 300;
  }

  .loadMoreArea {
    // overflow: hidden;
  }
  .loadMoreBtn {
    color: #fff;
    text-decoration: none;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
}

.homepageSeoAcr {
  background: unset;
  border: none;
  box-shadow: none;
  @media (max-width: 767px) {
    margin-top: 30px;
  }
  .accordion-body {
    padding: 0;
    background: unset;
    .homeElementBlock {
      margin: 0px;
      padding: 0px;
      @media (max-width: 767px) {
        padding: 15px;
      }
    }
  }
  .accordion-collapse {
    background: #090c23;
    border: none;
    box-shadow: none;
  }
  .accordion-header {
    button {
      background: #090c23;
      color: #fff;
      font-weight: 700;
      box-shadow: none;
      outline: 0;
      &::after {
        // background: "";
        background: url("../../assets/sweeplay/images/icons/angle-down-white.svg")
          no-repeat;
      }
    }
  }
  .accordion-item {
    border: none;
  }
}
