$blockBG: #11112b;
$blockBG2: #1e1b39;

.loyaltyProgram {
  color: $textColor;
  .pageHeading {
    margin: 30px;
  }
  .loyaltyHeadings {
    text-align: center;
    h1 {
      font-size: 2em;
      color: $textColor;
    }

    h2 {
      // font-size: 1.5em;
      color: #f85515;
    }
    p {
      color: $textColor;
    }
  }
  .loyalty-club-container {
    background-color: $blockBG;
    color: white;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 30px;
  }

  .loyalty-club-header {
    display: flex;
    align-items: center;
  }

  .back-arrow {
    margin-right: 10px;
    cursor: pointer;
  }

  .loyalty-level {
    display: flex;
    align-items: center;
    margin-top: 10px;
    h1,
    h2,
    p {
      color: $textColor;
    }
  }

  .loyalty-icon {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .loyalty-progress {
    margin-top: 20px;
  }

  .progress-bar-container {
    background-color: #333;
    height: 10px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
  }

  .progress-bar {
    background-color: #e0a800;
    height: 100%;
    transition: width 0.3s ease-in-out;
  }

  .progress-labels {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .progress-label {
    display: flex;
    align-items: center;
    span {
      color: $textColor;
    }
  }

  .progress-label img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  //second block
  .loyalty-rewards-container {
    // background-color: #1a1a1a;
    color: white;
    // padding: 20px;
    border-radius: 10px;
    text-align: center;
    margin: 30px 0;
  }

  .rewards-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }

  .reward-card {
    background-color: $blockBG2;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
  }

  .reward-card img {
    width: 100px;
    // height: 100px;
    margin-bottom: 10px;
  }

  .reward-card h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: $textColor;
  }

  .reward-card p {
    font-size: 0.9em;
    color: $textColor;
  }

  //Loyalty steps
  .loyalty-steps-container {
    // background-color: #1a1a1a;
    color: white;
    // padding: 20px;
    border-radius: 10px;
    text-align: center;
  }

  .steps-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin: 30px 0;
  }

  .step-card {
    background-color: $blockBG2;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    text-align: left;
    gap: 10px;
    .stepsCount {
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      border-radius: 100px;
      background: linear-gradient(
        177deg,
        #d585fa,
        #cc72f5 23.44%,
        #cc5fff 51.04%,
        #b549e8 70%,
        #a737db 92%
      );
      box-shadow: 2px 0 0 0 #7d28a5;
      color: #191919;
      font-size: 22px;
      font-weight: 700;
      line-height: 40px;
      text-align: center;
    }
    p {
      margin-bottom: 0;
      color: $textColor;
    }
  }

  .step-card img {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }

  .step-card p {
    font-size: 0.9em;
  }

  //Levels
  .levels-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @media (max-width: 767px) {
      grid-template-columns: 1fr 1fr;
    }
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 50px 15px;
    // gap: 20px;
    // background-color: #1c1c1c; /* Optional: Set a background color for the grid */
    // padding: 20px;
    border-radius: 10px;
    margin: 70px 0 30px;
  }

  .level-card {
    background: $blockBG;
    color: #fff;
    padding: 20px;
    // margin: 10px;
    border-radius: 10px;
    text-align: center;
    // width: 250px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }

  .level-icon {
    // background: linear-gradient(
    //   177deg,
    //   #f9ed84,
    //   #fde153 23.44%,
    //   #ffde45 51.04%,
    //   #eec02e 70%,
    //   #f4a52c 92%
    // );
    width: 80px;
    height: 80px;
    line-height: 80px;
    border-radius: 25px;
    margin: -60px auto auto;
  }
  .level-icon img {
    width: 80px;
    height: 80px;
  }

  .level-details {
    margin-top: 10px;
    h3 {
      color: $textColor;
    }
    p {
      margin-bottom: 0;
      font-size: 14px;
      color: $textColor;
    }
  }

  .highlight {
    border: 2px solid #f85515;
  }
  //VIP clubs
  .vip-club {
    // background-color: #1c1c1c; /* Background color for the whole component */
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
  }

  .vip-club h1 {
    font-size: 2em;
    margin-bottom: 10px;
  }

  .vip-club h2 {
    font-size: 1.2em;
    margin-bottom: 20px;
    color: #ccc;
  }

  .vip-section {
    background-color: $blockBG2; /* Background color for each section */
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 10px;
    text-align: left;
  }

  .vip-section h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: $textColor;
  }

  .vip-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .vip-section li {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    color: $textColor;
    &:last-child {
      margin-bottom: 0;
    }
    .checkmark {
      color: rgb(74, 121, 24);
      margin-right: 6px;
      img {
        width: 20px;
      }
    }
  }

  .vip-section li span {
    margin-right: 10px;
    color: gold; /* Checkmark color */
    background: linear-gradient(
      177deg,
      #f9ed84,
      #fde153 23.44%,
      #ffde45 51.04%,
      #eec02e 70%,
      #f4a52c 92%
    );
    width: 30px;
    height: 30px;
    line-height: 26px;
    border-radius: 50%;
    text-align: center;
  }
}
