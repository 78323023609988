@import "./config";
.security_overlay {
  position: fixed;
  z-index: 1000;
  background: $bg_color;
  width: 100vw;
  height: 100vh;
  .security_popup_logo {
    position: absolute;
    top: 42%;
    width: 100%;
    text-align: center;
    video,
    img {
      max-width: 250px;
      width: 90%;
    }
  }
  .security_popup_trigger {
    position: absolute;
    right: 20px;
    width: 150px;
    top: 25px;
    padding: 0 !important;
  }
}

.security_popup {
  padding: 20px !important;
  .modal_close {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 6px;
    top: 10px;
    cursor: pointer;
  }
  .modal_headline {
    font-size: 22px;
    text-align: center;
    margin: 15px auto;
  }
  .input_item {
    margin-bottom: 15px;
  }

  .login_button {
    text-align: center;
    button {
      width: 150px;
    }
  }
}
