$itemBackground: #11112b;

.topShortMenu {
  .banner_items {
    // display: none;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
    margin: 0;
    padding: 0;
    @media (max-width: 768px) {
      display: block;
    }
    li {
      border-radius: 15px;
      background: #191a26;
      height: 90px;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        margin-bottom: 15px;
      }
      a {
        display: grid;
        grid-template-columns: 70px 5fr;
        grid-gap: 15px;
        align-items: center;
        text-decoration: none;
        color: #fff;
        font-family: $fontFamily;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        padding: 0 10px;
        @media (min-width: 768px) and (max-width: 1199px) {
          padding: 0;
          grid-gap: 0;
        }

        .icon {
          // padding-left: 5px;
          text-align: center;
          img {
            margin: 0 auto;
            width: 50px;
            &.spinIcon {
              width: 70px;
            }
            &.tickIcon {
              width: 45px;
            }
            &.boxIcon {
              width: 60px;
            }
            @media (max-width: 1199px) {
              &.spinIcon {
                width: 50px;
              }
              &.tickIcon {
                width: 35px;
              }
              &.boxIcon {
                width: 50px;
              }
            }
          }
        }
        .text {
          text-align: left;
          color: #fff;
          font-family: $fontFamily;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 128.571% */
          // margin-top: 5px;
          display: block;
          padding-top: 5px;
        }
      }
      background-color: $itemBackground;

      //   &.bg-one {
      //     // background: url("assets/sweeplay/images/topShortMenu/banner-item-bg-one.svg")
      //     //   no-repeat;
      //     background: url("../../../assets/sweeplay/images/topShortMenu/banner-item-bg-one.svg")
      //       no-repeat;
      //     background-size: cover;
      //   }
      //   &.bg-two {
      //     background: url("../../../assets/sweeplay/images/topShortMenu/banner-item-bg-two.svg")
      //       no-repeat;
      //     background-size: cover;
      //   }
      //   &.bg-three {
      //     background: url("../../../assets/sweeplay/images/topShortMenu/banner-item-bg-three.svg")
      //       no-repeat;
      //     background-size: cover;
      //   }
      //   &.bg-four {
      //     background: url("../../../assets/sweeplay/images/topShortMenu/banner-item-bg-four.svg")
      //       no-repeat;
      //     background-size: cover;
      //   }
      &.jackpotTicker {
        display: block;
        text-align: center;
        padding-top: 5px;
        .heading {
          text-align: center;
          color: #fff;
          font-family: "Impacted";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 128.571% */
          // margin-top: 5px;
          display: block;
          padding-top: 5px;
          margin-bottom: 5px;
        }
      }
    }
  }
}
