$inputBg: linear-gradient(#19192c, #19192c) padding-box,
  linear-gradient(to bottom, rgba(255, 255, 255, 0.1), #19192c) border-box;
$hoverBg: linear-gradient(180deg, #3d4055 0%, rgba(51, 54, 71, 0) 127.81%);
$inputBorderColor: #252637;
$inputTextColor: #fff;

.input_item {
  position: relative;
  margin-bottom: 15px;
  .name {
    color: $textColor;
    margin-bottom: 5px;
    .forgot_pass_link {
      color: $white;
    }
    .requried_icon {
      color: red;
    }
  }

  .name_value {
    background: #202536;
    border: none;
    color: $inputTextColor;
    transition-duration: 300ms;
    border: 1px solid #9aa0b1;
    // border-bottom: 1px solid $inputBorderColor;
    box-shadow: inset 0 0 0 1 #9aa0b1;
    outline: 0;
    border-radius: 10px;
    width: 100%;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    &::placeholder {
      color: #b3b4c4;
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder {
      /* Edge 12 -18 */
      color: #b3b4c4;
    }
    &[readonly] {
      opacity: 0.8 !important;
      background: $inputBg !important;
      // border-bottom: 1px solid $inputBorderColor !important;
    }

    &[disabled] {
      background-color: unset;
    }
    &:hover {
      background: $inputBg !important;
      // border: none !important;
      // color: $text_color_v1_dark !important;
      // border-bottom: 1px solid $inputBorderColor !important;
      outline: none !important;
      // box-shadow: 0px 1px 1px orange !important;
      // box-shadow: 0px 1px 1px rgba(97, 105, 114, 1) !important;
    }
    &:focus {
      outline: none !important;
      box-shadow: none !important;
      background: $inputBg !important;
      // border: none !important;
      // color: $text_color_v1_dark !important;
      border: 1px solid orange !important;
      // box-shadow: 0px 1px 1px orange !important;
      color: #fff !important;
    }
    &.phoneNumber {
      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &.input[type="number"] {
        -moz-appearance: textfield;
      }
    }

    option {
      background: #000 !important;
    }

    &.error {
      color: red;
      border-color: red;

      &:focus {
        border-color: red !important;
      }
    }
  }

  .in_input_icon {
    position: absolute;
    right: 10px;
    top: 40px;
    i {
      color: $white;
      &:hover {
        color: $inputBorderColor;
      }
    }
  }
}

.login_reg_area {
  .input_item .name_value {
    height: 48px;
  }
}
