$textColor: #fff;

.sideMenuBar {
  width: 50px;
  height: 50px;
  background-color: #1e1b39;
  text-align: center;
  line-height: 45px;
  border-radius: 15px;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    line-height: 35px;
  }
}

// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// textarea:-webkit-autofill,
// textarea:-webkit-autofill:hover,
// textarea:-webkit-autofill:focus,
// select:-webkit-autofill,
// select:-webkit-autofill:hover,
// select:-webkit-autofill:focus {
//   border: 1px solid #19192c;

// -webkit-text-fill-color: white;
//   -webkit-box-shadow: 0 0 0px 1000px #19192c inset;
//   transition: background-color 5000s ease-in-out 0s;
// }

.dark-theme-popup {
  background: #2c2c2c !important;
  color: #ffffff !important;
}

.dark-theme-title {
  color: #ffffff !important;
}

.dark-theme-content {
  color: #ffffff !important;
}

.dark-theme-confirm-button {
  background-color: #3085d6 !important;
  border-color: #3085d6 !important;
  color: #ffffff !important;
}

.subTitle {
  color: #c3c6cf;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #100c0c;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1b1818;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  color: $textColor;
}

iframe {
  z-index: -1 !important;
}

$textColor: #fff;

.backDrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: #00000022;
}

.logo {
  text-decoration: none !important;
  display: flex;
  gap: 5px;
  align-items: center;

  span {
    font-family: "Franklin Gothic";
    color: white;
    font-size: 36px;
    font-style: italic;
    text-transform: uppercase;
    text-decoration: none !important;
  }
  &:hover {
    text-decoration: none !important;
  }
}

.gameHeading {
  font-size: 28px;
  color: $textColor;
  font-weight: 700;
}

.slick-arrow.slick-prev {
  left: -10px;
  z-index: 10;
}
.slick-arrow.slick-next {
  right: -10px;
  z-index: 10;
}

.slick-dots li.slick-active button:before {
  color: orange;
  width: 20px;
  height: 4px;
  background-color: orange;
  content: "" !important;
  top: 8px;
  border-radius: 5px;
}
.slick-dots li button:before {
  color: orange;
}

body {
  background-color: #090c23 !important;
}

.logo {
  img {
    width: 40px;
  }
}

.headline_text,
.common_headline {
  color: #fff;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 20px;
}
.error_msg {
  color: red;
}
