@import "config.scss";
.pagination {
  border-radius: 6px;
  margin-top: 30px;
  @media (min-width: 768px) {
    width: fit-content;
  }

  li.page-item {
    // background: $bg_color;
    height: 50px;
    width: 50px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 50px;
    text-align: center;

    button {
      margin: 0 1px;
      text-decoration: none;
      color: $main_color;
      border: 1px solid $main_color;
      width: 100%;
      height: 100%;
      display: block;
      &:hover {
        background: $main_color;
        color: $white;
      }
      &.first {
        display: none;
      }
      &.last {
        display: none;
      }
      &.current-page {
        background: $main_color;
        color: $white;
      }
    }

    @media (max-width: 767px) {
      height: 35px;
      width: 35px;
      line-height: 35px;
    }
  }
}
