.other_method_area {
  color: white;
  ul {
    padding: 0px;
    margin: 30px 0;
    list-style: none;
    // display: grid;
    // grid-template-columns: repeat(4, 1fr);
    // grid-template-rows: 1fr;
    // grid-gap: 10px;

    li {
      cursor: pointer;
      // width: 150px;
      // height: 50px;
      display: block;
      // border: 2px solid $white;
      margin-bottom: 15px;
      list-style: none;
      // padding: 5px;
      text-align: center;
      // display: flex;
      align-items: center;
      justify-content: center;
      background-color: #353440;
      border-radius: 5px;
      .img {
        float: left;
        width: 50%;
        text-align: left;
      }
      img {
        height: 30px;
      }
      .text {
        // float: left;
        // width: 50%;
        // text-align: left;
        background-color: gray;
        border-radius: 5px 5px 0 0;
        text-align: left;
        font-size: 12px;
        text-transform: uppercase;
        padding: 5px 10px;
      }
      &.active {
        border: 1px solid #ff8a1e;
      }
      label {
        margin-bottom: 0px;
        padding: 15px 10px;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      input[type="radio"] {
        display: none;
      }
    }
  }
  &.redeem-method {
    // width: 300px;
    .redeemBtn {
      background: linear-gradient(160deg, #1e5128 0%, #4ea03e 100%);
      color: $grayBlack_bg;
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
      display: block;
      // width: 100%;
      border-radius: 4px;
      height: 35px;
      line-height: 35px;
      text-decoration: none;
      padding: 0 15px;
      border: none;
      margin: 0 auto;
      &:hover {
        background-color: #ff8a1e;
      }
    }
  }
}

.redeemArea {
  .user_blocks {
    .single_block {
      display: block;
    }
  }
}
